import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Button, Modal } from "react-bootstrap";
import { generateForm } from "../../utils/AppFunction";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../appContext/AppContext";
import { downloadPhieuKiemNhap } from "../services/services";
import { formatDateDTO } from "../../utils/FormatUtils";
import GenerateFormComponent from "../../component/generate-form/GenerateFormHTMLNoValid";
import { IconButtonSave } from "../../component/IconSvg";
import { localStorageItem } from '../../../../app/modules/utils/LocalStorage'


interface Props {
  show: boolean;
  onHide: () => void;
  slipId: string;
  generalValues: any;
  handleFormSubmit: (values: any) => void;
}

const DialogKiemKeNhapKho = (props: Props) => {
  const { show, onHide, slipId, generalValues, handleFormSubmit } = props;
  const { setIsLoading } = useContext(AppContext);
  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);
  const validationSchema = Yup.object({});
  const orgname = localStorageItem.get("id-token-decode")?.orgname

  const updateForm = async () => {
    const data = await generateForm("phieuKiemNhap", setIsLoading);
    setListJsonGenerate(data || []);
  };

  const handleSubmit = async (values: any) => {
    handleFormSubmit({
      ...generalValues,
      ...values,
    });
  };

  useEffect(() => {
    updateForm();
  }, []);

  const handleDownload = async (values: any) => {
    try {
      setIsLoading(true);
      const { phieuKiemNhap: data } = values;
      const response = await downloadPhieuKiemNhap(
        {
          parameters: [
            { name: "benh_vien", value: orgname || "" },
            { name: "slip_id", value: slipId },
            { name: "ngay", value: formatDateDTO(new Date()) },
            { name: "person_1", value: data.person1Name || '' },
            { name: "person_2", value: data.person2Name || '' },
            { name: "person_3", value: data.person3Name || '' },
            { name: "person_4", value: data.person4Name || '' },
            { name: "person_5", value: data.person5Name || '' },
            { name: "person_6", value: data.person6Name || '' },
            { name: "person_7", value: data.person7Name || '' },
          ]
        },
      );
      const blob = new Blob([response.data], { type: 'application/xls' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `excel.xls`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal centered show={show} onHide={onHide} size="lg" className=" spaces">
      <Formik
        enableReinitialize
        initialValues={{ phieuKiemNhap: { ...generalValues.nhapKho } }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          touched,
          errors,
          setValues,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className="">
                <Modal.Header className="header-modal min-h-40px">
                  <div className="d-flex gap-6">
                    <div
                      className="d-flex align-items-center title-back gap-3 cursor-pointer"
                      onClick={onHide}
                    >
                      <i className="spaces bi bi-chevron-left ml-10"></i>
                      <div className="spaces mr-20 my-3">Quay lại</div>
                    </div>
                    <div className="spaces mt-4 title">In biên bản kiểm nhập</div>
                  </div>
                </Modal.Header>
                <Modal.Body className="spaces p-0 bg-white">
                  <div className="spaces px-10">
                    <GenerateFormComponent
                      listFieldAuto={listJsonGenerate}
                      setValues={setValues}
                      values={values}
                      touched={touched}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      modelID={"phieuKiemNhap"}
                    />
                  </div>

                  <div className=" d-flex justify-content-end w-100 spaces py-10 pr-7">
                    <Button
                      className="btn btn-fill spaces min-w-120 mx-3"
                      type="submit"
                    >
                      <IconButtonSave /> Lưu
                    </Button>
                    <Button
                      className="btn btn-fill spaces min-w-120 mx-3"
                      onClick={() => handleDownload(values)}
                    >
                      Tải biên bản
                    </Button>
                  </div>
                </Modal.Body>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default DialogKiemKeNhapKho;
