const DB_NAME = "MyDatabase";
const DB_VERSION = 2;
const STORE_NAME = "DS_DANH_MUC";

const openDatabase = () => {
    return new Promise<IDBDatabase>((resolve, reject) => {
        const request = indexedDB.open(DB_NAME, DB_VERSION);

        request.onerror = (event) => {
            reject(event.target);
        };

        request.onsuccess = (event) => {
            resolve(request.result);
        };

        request.onupgradeneeded = (event) => {
            const db = (event.target as IDBOpenDBRequest).result;
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                db.createObjectStore(STORE_NAME, { keyPath: "key" });
            }
        };
    });
}

const addIndexedDBItem = async (key: string, value: any) => {
    try {
        const db = await openDatabase();
        return new Promise<void>((resolve, reject) => {
            const transaction = db.transaction([STORE_NAME], "readwrite");
            const store = transaction.objectStore(STORE_NAME);
            const request = store.add({ key, value });

            request.onsuccess = () => {
                resolve();
            };

            request.onerror = () => {
                reject(request.error);
            };
        });
    } catch (error) {
        console.error(error);
    }
}

const getIndexedDBItem = async (key: string) => {
    try {
        const db = await openDatabase();
        return new Promise<any>((resolve, reject) => {
            const transaction = db.transaction([STORE_NAME]);
            const store = transaction.objectStore(STORE_NAME);
            const request = store.get(key);

            request.onsuccess = () => {
                resolve(request.result?.value);
            };

            request.onerror = () => {
                reject(request.error);
            };
        });
    } catch (error) {
        console.error(error);
    }
}

const deleteIndexedDBItem = async (key: string) => {
    try {
        const db = await openDatabase();
        return new Promise<void>((resolve, reject) => {
            const transaction = db.transaction([STORE_NAME], "readwrite");
            const store = transaction.objectStore(STORE_NAME);
            const request = store.delete(key);

            request.onsuccess = () => {
                resolve();
            };

            request.onerror = () => {
                reject(request.error);
            };
        });
    } catch (error) {
        console.error(error);
    }
}


const openDatabaseByName = (dbObj : any): Promise<IDBDatabase> => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(dbObj.name, 1);
  
        request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
            const db = (event.target as IDBOpenDBRequest).result;
            db.createObjectStore(dbObj.name, { keyPath: dbObj.keyPath });
        };
  
        request.onsuccess = () => {
            resolve(request.result);
        };
  
        request.onerror = (event) => {
            console.error("Error opening the database:", (event.target as IDBOpenDBRequest).error);
            reject("Error opening the database");
        };
    });
};
  
const saveDataToIndexedDB = (
    db: IDBDatabase,
    codeAPI: string,
    data: any,
    dbObj: any
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(dbObj.name, "readwrite");
        const store = transaction.objectStore(dbObj.name);
  
        const request = store.put({ [dbObj.keyPath]: codeAPI, data, });
  
        request.onsuccess = () => resolve();
        request.onerror = () => reject("Error saving data to IndexedDB");
    });
};
  
const getDataFromIndexedDB = (db: IDBDatabase, codeAPI: string, dbObj : any): Promise<string | null> => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(dbObj.name, "readonly");
      const store = transaction.objectStore(dbObj.name);
      const request = store.get(`${dbObj?.namePrefix ? dbObj.namePrefix : ""}${codeAPI}`);
      request.onsuccess = () => {
          resolve(request.result ? request.result.data : null);
      };
      request.onerror = () => {
          reject("Error retrieving data from IndexedDB");
      };
    });
};
  
const deleteDatabase = (dbName: string): Promise<void> => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.deleteDatabase(dbName);

        request.onsuccess = () => {
            console.log(`Database ${dbName} deleted successfully.`);
            resolve();
        };

        request.onerror = (event) => {
            console.error("Error deleting database:", (event.target as IDBOpenDBRequest).error);
            reject("Error deleting database");
        };

        request.onblocked = () => {
            console.warn(`Database ${dbName} deletion is blocked.`);
            reject("Database deletion is blocked");
        };
    });
};

const clearAllIndexedDB = async (dbName: string) => {
    try {
        await deleteDatabase(dbName);
    } catch (error) {
        console.error("Error deleting database:", error);
    }
};

export { addIndexedDBItem, getIndexedDBItem, deleteIndexedDBItem, openDatabaseByName, saveDataToIndexedDB, getDataFromIndexedDB, clearAllIndexedDB };



