import { ChangeEvent, ReactNode, useContext, useEffect, useState } from "react";
import InputSearch from "../../InputSearch";
import { AppContext } from "../../../appContext/AppContext";
import { KEY } from "../../autocompleteOct/models";
import { TableCustom } from "../../table/table-custom/TableCustom";
import { CODE, DEFAULT_PAGE_INDEX, SELECTION_MODE } from "../../../utils/Constant";
import { TablePagination } from "../../table/components/TablePagination";
import { handlePagesChange, handleRowsPerPageChange, rowsForPage } from "../../../utils/PageUtils";
import { columnsDSVatTu } from "../const/Columns";
import { searchVatTu } from "../QuanLyKhoServices";
import { toast } from "react-toastify";
import "../styles.scss"

interface TableDSVatTuProps {
    warehouseId: number | undefined;
    customTitle?: ReactNode;
}

const TableDSVatTu: React.FC<TableDSVatTuProps> = (props) => {
    const { warehouseId, customTitle } = props;
    const { setIsLoading } = useContext(AppContext);
    const [dsVatTu, setDsVatTu] = useState([]);
    const [searchData, setSearchData] = useState<any>();
    const [rowsPerPage, setRowsPerPage] = useState<number>(20);
    const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
    const [configTable, setConfigTable] = useState<any>({
        totalPages: 0,
        totalElements: 0,
        numberOfElements: 0,
    });

    const handleChangeSearchData = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchData({
            ...searchData,
            [e.target.name]: e.target.value,
        });
    };

    const updatePageData = async () => {
        try {
            setIsLoading(true);
            const { data } = await searchVatTu({ ...searchData, pageIndex: page, pageSize: rowsPerPage, warehouseId });
            if (CODE.SUCCESS === data.code) {
                setDsVatTu(data.data.content);
                setConfigTable({
                    totalPages: (data.data.pageable.totalElements / data.data.pageable.pageSize).toFixed(),
                    totalElements: data?.data?.pageable?.totalElements,
                    // numberOfElements: data?.data?.pageable?.pageNumber,
                })
            } else {
                toast.error('Có lỗi xảy ra, vui lòng thử lại');
            };
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (warehouseId || warehouseId === 0) {
            updatePageData();
        }
    }, [page, rowsPerPage, warehouseId]);
    return (
        <div className="bg-white h-100">
            <div className="flex-1 flex flex-column">
                <div className="d-flex bg-gray ">
                    <div className="spaces width-100 bg-gray radius-2">
                        <div className="flex justify-content-between bg-white spaces width-100 " >
                            <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 px-10 flex gap-6">
                                {customTitle ? customTitle : 'Danh sách vật tư'}
                            </h4>
                        </div>
                        <div className="d-flex  mt-1 flex-column spaces width-100">
                            <div className="bg-white">
                                <div className="d-flex spaces px-8 mt-6 bg-white justify-content-between align-items-center mb-6">
                                    <div className="flex-grow-1 ">
                                        <InputSearch
                                            name="keyword"
                                            value={searchData?.keyword || ''}
                                            placeholder="Tìm kiếm theo mã hoặc tên thuốc/vật tư"
                                            handleChange={handleChangeSearchData}
                                            handleSearch={updatePageData}
                                            handleKeyDown={(e: React.KeyboardEvent) => e.key === KEY.ENTER && updatePageData()}
                                            className="spaces h-31 pr-4  radius-3"
                                        />
                                    </div>
                                </div>
                                <div className=" spaces bg-white mb-0">
                                    <TableCustom<any>
                                        className="spaces h-bang-ds-vat-tu-ton-kho"
                                        data={dsVatTu}
                                        columns={columnsDSVatTu}
                                        verticalScroll={true}
                                        selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
                                    />
                                    <TablePagination
                                        handlePagesChange={handlePagesChange}
                                        handleRowsPerPageChange={handleRowsPerPageChange}
                                        rowsPerPage={rowsPerPage}
                                        rowsForPage={rowsForPage}
                                        page={page}
                                        setPage={setPage}
                                        setRowsPerPage={setRowsPerPage}
                                        totalPages={Number(configTable.totalPages)}
                                        totalElements={configTable.totalElements}
                                        numberOfElements={configTable.numberOfElements}
                                        className="border border-0"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TableDSVatTu;