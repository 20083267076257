import { Row } from "react-bootstrap";
import { TYPE_VT } from "./const/ThuNganConstant";
import { AppContext } from "../appContext/AppContext";
import { useContext, useEffect, useState } from "react";
import ThongTinVienPhi from "./components/ThongTinPhieuThu";
import ThongTinBenhNhan from "./components/ThongTinBenhNhan";
import ContextThanhToanProvider from "./context/PhanHeThuNganContext";
import { TableDanhSachBenhNhan } from "./components/TableDanhSachBenhNhan";
import { CODE, WF_TRACKER } from "../utils/Constant";
import "./PhanHeThuNgan.scss";
import { getSummaryStatusByType } from "../workflows/services/WfWorkflowsService";
import { getDetailThongTinThanhToan, getListPhieuThu, searchVatTuThanhToan } from "./services/PhanHeThuNganServices";
import { toast } from "react-toastify";
import DialogThemMoiPhieuThu from "./ThemMoiPhieuThu/DialogThemMoiPhieuThu";
import { EXPORT_OPTIONS } from "../phan-he-xuat-kho/constants/constantsXuatKho";

const PhanHeVienPhi = () => {
  const [selectedRow, setSelectedRow] = useState<any>({});
  const { setIsLoading } = useContext(AppContext);
  const [dsVatTu, setDsVatTu] = useState([]);
  const [showDialogThemMoi, setShowDialogThemMoi] = useState<boolean>(false);
  const [summaryStatus, setSummaryStatus] = useState<any>([]);
  const [searchDataBN, setSearchDataBN] = useState<any>(null);
  const [dsPhieu, setDsPhieu] = useState<any[]>([]);

    const updateSummaryStatus = async () => {
      try {
      setIsLoading(true);
      const resSummary = await getSummaryStatusByType(WF_TRACKER.THU_NGAN, parseFloat(searchDataBN?.type))
      if (CODE.SUCCESS === resSummary.data?.code) {
        setSummaryStatus(resSummary.data?.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setShowDialogThemMoi(false);
  };

  const handleRefreshData = async (rowData: any, isUpdateSummaryStatus?: boolean, isUpdateDsBenhNhan?: boolean) => {    
    if (!rowData?.id) {
      setSelectedRow({});
      setDsVatTu([]);
      setDsPhieu([]);
      return;
    }
    setIsLoading(true);
    try {
      const [detailResponse, vatTuResponse, listPhieuThuResponse] = await Promise.all([
        getDetailThongTinThanhToan(rowData.id),
        searchVatTuThanhToan({ slipId: rowData.id }),
        getListPhieuThu(rowData.code),
      ]);
      if (isUpdateSummaryStatus) {
        const summaryResponse = await getSummaryStatusByType(WF_TRACKER.THU_NGAN, parseFloat(searchDataBN?.type));
        CODE.SUCCESS === summaryResponse.data?.code && setSummaryStatus(summaryResponse.data?.data);
      };
      setDsPhieu(listPhieuThuResponse?.data?.data?.content || []);
      const { data: detailData } = detailResponse;
      const { data: vatTuData } = vatTuResponse;
      if (CODE.SUCCESS === detailData?.code && CODE.SUCCESS === vatTuData?.code) {
        setSelectedRow({...detailData.data, id: detailData.data?.id || rowData.id});
        setDsVatTu(vatTuData.data.content);
        if (isUpdateDsBenhNhan) {
          setSearchDataBN({
            type: TYPE_VT.THUOC,
          });
        };
      } else {
        toast.error('Lỗi làm mới thông tin, vui lòng thử lại');
      };
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(!!isUpdateDsBenhNhan);
    }
  };

  useEffect(() => {
    searchDataBN?.type && updateSummaryStatus();
    setSelectedRow({});
    setDsVatTu([]);
  }, [searchDataBN?.type]);

  return (
    <div className="reception-list bg-gray">
      <ContextThanhToanProvider>
        <Row className="phanHeVienPhi h-100">
          <div className="pe-0 dsBenhNhan spaces width-27_4 h-calc-vh-35">
            <TableDanhSachBenhNhan
              searchData={searchDataBN}
              setSearchData={setSearchDataBN}
              handleRefreshData={handleRefreshData}
              summaryStatus={summaryStatus}
            />
          </div>
          <div className="pl-0 dsBenhNhan ml-pt-0_6 spaces border width-72 ">
            <ThongTinBenhNhan
              selectedRow={selectedRow}
              setShowDialogThemMoi={setShowDialogThemMoi}
            />
            <ThongTinVienPhi
              warehouseId={32}
              dsVatTu={dsVatTu}
              setDsVatTu={setDsVatTu}
              dsPhieu={dsPhieu}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              handleRefreshData={handleRefreshData}
              updateSummaryStatus={updateSummaryStatus}
            />
            {showDialogThemMoi && (
              <DialogThemMoiPhieuThu
                show={showDialogThemMoi}
                onHide={handleCloseDialog}
                warehouseId={32}
                warehouseExportType={EXPORT_OPTIONS[1]}
                handleRefreshData={handleRefreshData}
              />
            )}
          </div>
        </Row>
      </ContextThanhToanProvider>
    </div>
  );
};

export default PhanHeVienPhi;
