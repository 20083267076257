//@ts-nocheck
import "../../../../../app/modules/styles/index.scss";
import { localStorageItem } from "../../../../../app/modules/utils/LocalStorage";
import { MenuItem } from "./MenuItem";
import { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export function MenuInner() {
  const containerRef = useRef(null);
  const [dragStart, setDragStart] = useState(0);
  const [scrollStart, setScrollStart] = useState(0);
  const configuration = localStorage.getItem('configuration');
  const oauthClientUrl = JSON.parse(configuration)?.oauthClientUrl || '';
  const listMenu = localStorageItem.get("id-token-decode")?.menus?.["oceantech-wms-server"]

  const navigate = useNavigate();
  const handleMouseDown = (e: any) => {
    setDragStart(e.clientX);
    setScrollStart(containerRef?.current.scrollLeft);
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };
  
  const handleMouseMove = (e: any) => {
    const dragDelta = e.clientX - dragStart;
    containerRef.current.scrollLeft = scrollStart - dragDelta;
  };
  const scrollToLeft = () => {
    containerRef.current.scrollLeft -= 200;
  };

  const scrollToRight = () => {
    containerRef.current.scrollLeft += 200;
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  return (
    <div className="d-flex align-items-center">
      <div className="d-flex align-items-center">
        <MenuItem to={oauthClientUrl} icon="/media/icons/menu.svg" />
        {/* <MenuItem to="/home" icon="./media/icons/menu.svg" /> */}
        <i
          className="bi bi-chevron-bar-left fs-2 cursor-pointer"
          onClick={scrollToLeft}
        ></i>
        <div
          className="header-menu-container"
          ref={containerRef}
          onMouseDown={handleMouseDown}
        >
          {
            (listMenu?.[0]?.subs || [])?.map((item, index) => {
              return <MenuItem to={item?.uri} title={item?.name} />
            })
            // <MenuItem to="/phan-he-thong-ke" title="Thống kê" />
            //  <MenuItem to="/quan-ly-thau" title="Quản lý thầu" />
            // <MenuItem to="/phan-he-nhap-kho" title="Nhập kho" />
            // <MenuItem to="/phan-he-xuat-kho" title="Xuất kho" />
            // <MenuItem to="/phan-he-dieu-chuyen-kho" title="Điều chuyển kho" /> 
            // <MenuItem to="/kho-vat-tu" title="Kho vật tư" />
            // <MenuItem to="/kho-nha-thuoc" title="Kho nhà thuốc" />
            // <MenuItem to="/kho-noi-vien" title="Kho nội viện" />
            // <MenuItem to="/kho-y-cu" title="Kho y cụ" />
            // <MenuItem to="/kho-hoa-chat" title="Kho hóa chất" />
            // <MenuItem to="/phan-he-thu-ngan" title="Nhà thuốc - thu ngân" />
            // <MenuItem to="/the-kho" title="Thẻ kho" />
            // <MenuItem to="/kiem-ke" title="Kiểm kê" />
            // <MenuItem to="/danh-muc" title="Danh mục" />
            // <MenuItem to="/phan-he-tu-truc" title="Tủ trực" />
            // <MenuItem to="/quan-tri-he-thong" title="Quản trị hệ thống" /> 
          }
        </div>
      </div>
      <i
        className="bi bi-chevron-bar-right fs-2 cursor-pointer"
        onClick={scrollToRight}
      ></i>
    </div>
  );
}
