import * as Yup from "yup";
import { toast } from "react-toastify";
import { CODE } from "../../utils/Constant";
import { Formik } from "formik";
import { OctTable } from "@oceantech/oceantech-ui";
import { Button, Form, Modal } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../appContext/AppContext";
import { ColumnThongTinThuNgan } from "../const/ThuNganColumns";
import { generateForm, handleCaculateTotalAmount, roundToDecimalPlaces } from "../../utils/AppFunction";
import GenerateFormComponent from "../../component/generate-form/GenerateFormHTMLNoValid";
import { searchVatTuThanhToan, updateThongTinThanhToan } from "../services/PhanHeThuNganServices";
import DynamicComponent from "../../component/GenerateDynamicComponent";
import { useIntl } from "react-intl";

type Props = {
  warehouseId?: any;
  show: boolean;
  onHide: () => void;
  data: any;
  idPhieuThanhToan: string;
  selectedRow: any;
  setSelectedRow: React.Dispatch<any>;
};

const GenerateFormInput = ({ data, warehouseId, listFieldAuto, handleChange, setValues, values, touched, errors, setFieldValue }: any) => {

  const handleVatTuSelected = (valuesSelected: any) => {
    const dataForm = values.formCapNhapVatTuPhieuThu || {};
    const dataItem = valuesSelected || {};
    const newFormData = {
      ...data,
      id: dataForm.id,
      itemId: dataItem.id,
      item: dataItem.code,
      itemName: dataItem.name,
      name: dataItem.name,
      code: dataItem.code,
      unitId: dataItem.unitofMeasureName,
      manufacturer: dataItem.manufacturer,
      countryofOrigin: dataItem.countryofOrigin, 
      iventory: dataItem.iventory || 0,
      availableQuantity: dataItem.availableQuantity || 0,
      specifications: dataItem.specifications,
      totalQuantity: '',
      sellingPrice: dataItem.sellingPrice,
      hospitalPrice: dataItem.hospitalPrice,
      vat: dataItem.vat,
      totalAmount: '',
      regisNum: dataItem.regisNum,
      consignment: dataItem.consignment,
      expiryDate: dataItem.expiryDate,
      usageIns: dataItem.usageIns,
      diagnosis: dataItem.diagnosis,
      serialNumber: dataItem.serialNumber,
    };
    setFieldValue('formCapNhapVatTuPhieuThu', newFormData);
  };

  const hanldeTotalAmount = () => {
    const dataForm = values.formCapNhapVatTuPhieuThu || {};
    const price = parseFloat(dataForm.hospitalPrice || 0);
    const quantity = parseFloat(dataForm.totalQuantity || 0);
    const vat = parseFloat(dataForm.vat || 0);
    const result = (price * quantity) * (1 + vat / 100);
    setFieldValue('formCapNhapVatTuPhieuThu', { ...dataForm, totalAmount: roundToDecimalPlaces(result) });
  };

  useEffect(() => {
    hanldeTotalAmount();
  }, [values.formCapNhapVatTuPhieuThu?.totalQuantity, values.formCapNhapVatTuPhieuThu?.hospitalPrice, values.formCapNhapVatTuPhieuThu?.vat]);

  return (
    <GenerateFormComponent
      listFieldAuto={listFieldAuto}
      handleChange={handleChange}
      setValues={setValues}
      warehouseId={warehouseId}
      values={values}
      touched={touched}
      errors={errors}
      DynamicClass={"pl-36"}
      setFieldValue={setFieldValue}
      modelID={"formCapNhapVatTuPhieuThu"}
      customComponent={DynamicComponent}
      propsRenderButton={handleVatTuSelected}
    />
  )
}

const FormUpdateVatTuPhieuThu: React.FC<Props> = (props) => {
  const { show, warehouseId, onHide, data, selectedRow, setSelectedRow, idPhieuThanhToan } = props;
  const { setIsLoading } = useContext(AppContext);
  const intl = useIntl();
  const [initialValues, setInitialValues] = useState({ formCapNhapVatTuPhieuThu: { ...data, code: null, id: null } });
  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);
  const [listVatTu, setListVatTu] = useState<any>([]);
  const [indexRowEdit, setIndexRowEdit] = useState<any>(null);
  const validationSchema = Yup.object({
    formCapNhapVatTuPhieuThu: Yup.object({})
  });

  const handleSubmit = async () => {
    try {
      if (!listVatTu.length) {
        toast.warning('Chưa có thuốc/VT');
        return;
      };
      setIsLoading(true);
      const amount = handleCaculateTotalAmount(listVatTu, 'hospitalPrice')
      const dataSubmit = {
        slipDto: {
          ...data,
          amount: amount,
          notCollected: amount - data.collected - data.discount,
        },
        lstItem: listVatTu.map((item: any) => ({ ...item, totalAmount: item?.totalAmount || item?.item?.totalAmount, warehouseId: selectedRow[0]?.warehouseId || 32 })),
      };
      const response = await updateThongTinThanhToan(idPhieuThanhToan, dataSubmit);
      if (CODE.SUCCESS === response.data?.code) {
        const resData = response.data.data;
        let newSelectedRow = { ...selectedRow };
        newSelectedRow = {
          ...newSelectedRow,
          amount: resData.amount,
          notCollected: resData.notCollected,
        };
        setSelectedRow(newSelectedRow);
        toast.success('Cập nhật thông tin thành công');
        onHide();
      }
    } catch (error) {
      setIsLoading(false);
      toast.error('Có lỗi xảy ra, vui lòng thử lại');
      console.error(error);
    }
  };

  const handleAddRow = (values: any) => {
    const dataForm = values.formCapNhapVatTuPhieuThu || {};
    const quantity = parseFloat(dataForm.totalQuantity);
    if (!dataForm.item) {
      toast.warning('Chưa chọn vật tư');
      return;
    };
    if (!quantity || quantity <= 0 || quantity > dataForm?.availableQuantity) {
      const message = !quantity && quantity !== 0
        ? 'Chưa nhập số lượng'
        : quantity <= 0
          ? 'Số lượng phải lớn hơn 0'
          : `Số lượng khả dụng không đủ (khả dụng: ${dataForm?.availableQuantity || 0})`
      toast.warning(message);
      return;
    };
    const newFormData = {
      inventoryId: data.inventoryId,
      ...dataForm,
    };
    if (indexRowEdit === null) {
      setListVatTu([...listVatTu, newFormData]);
    } else {
      let newListVatTu = [...listVatTu];
      newListVatTu[indexRowEdit] = newFormData;
      setListVatTu(newListVatTu);
      setIndexRowEdit(null);
    };
    setInitialValues({ formCapNhapVatTuPhieuThu: { ...data, code: null, id: null, randomValue: Math.random() } })
  };

  const hanldeDeleteRow = (index: number) => {
    const data = [...listVatTu].filter((_: any, i: number) => i !== index);
    setListVatTu(data);
  };

  const handleEditRow = (rowData: any, index: number) => {
    setInitialValues({
      formCapNhapVatTuPhieuThu: {
        ...initialValues.formCapNhapVatTuPhieuThu,
        ...rowData,
        name: rowData.itemName,
      }
    });
    setIndexRowEdit(index);
  };

  const updateData = async () => {
    try {
      setIsLoading(true);
      const res = await searchVatTuThanhToan({ slipId: data.id });
      if (CODE.SUCCESS === res.data?.code) {
        const dataConvert = res.data?.data?.content?.map((vatTu: any) => ({
          // ...vatTu,
          id: vatTu.id,
          itemId: vatTu.itemId,
          item: vatTu.item?.code,
          itemName: vatTu.item?.name,
          code: vatTu.item?.code,
          unitId: vatTu.item?.unitofMeasureName,
          manufacturer: vatTu.item?.manufacturer,
          countryofOrigin: vatTu.item?.countryofOrigin, 
          iventory: vatTu.item?.iventory || 0,
          availableQuantity: vatTu.item?.availableQuantity || 0,
          specifications: vatTu.item?.specifications,
          totalQuantity: vatTu.totalQuantity,
          sellingPrice: vatTu?.sellingPrice || vatTu.item?.sellingPrice,
          hospitalPrice: vatTu?.hospitalPrice || vatTu.item?.hospitalPrice,
          vat: vatTu.vat || vatTu.item?.vat || '',
          totalAmount: vatTu.totalAmount || 0,
          regisNum: vatTu.item?.registrationNumber,
          consignment: vatTu.consignment || vatTu.item?.consignment,
          expiryDate: vatTu.expiryDate || vatTu.item?.exDate,
          usageIns: vatTu.usageIns,
          accept: vatTu.accept,
          serialNumber: vatTu.serialNumber || vatTu.item?.serialNumber,
        }))
        setListVatTu(dataConvert);
      } else {
        toast.error('Lỗi tải danh sách vật tư, vui lòng thử lại');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const updateForm = async () => {
    const dataConvert = await generateForm("formCapNhapVatTuPhieuThuVatTu");
    setListJsonGenerate(dataConvert || []);
  };

  useEffect(() => {
    data?.type && updateForm();
  }, [data]);

  useEffect(() => {
    updateData();
  }, [data.id]);

  const columnActions = [
    {
      name: "TT",
      field: "",
      headerStyle: {
        minWidth: 60,
        maxWidth: 60,
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => (
        <>
          <i
            onClick={(e) => hanldeDeleteRow(index)}
            className="bi bi-trash3-fill text-danger spaces ml-10"
          ></i>
          <i
            onClick={(e) => handleEditRow(row, index)}
            className="bi bi-pencil-square text-navy spaces ml-10"
          ></i>
        </>
      ),
    },
  ]

  return (
    <Modal
      show={show}
      onHide={onHide}
      fullscreen
      centered
      className="page-full"
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, touched, errors, setValues, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header className="header-modal min-h-40px">
              <div className="d-flex gap-6">
                <div
                  className="d-flex align-items-center title-back gap-3 cursor-pointer"
                  onClick={onHide}
                >
                  <i className="spaces bi bi-chevron-left ml-10"></i>
                  <div className="spaces mr-20 my-3">Quay lại</div>
                </div>
                <div className="spaces mt-4 title">Cập nhật vật tư thanh toán</div>
              </div>
            </Modal.Header>
            <Modal.Body className="spaces p-0 bg-white">
              <div className="spaces px-10 pb-10">
                <GenerateFormInput
                  data={data}
                  warehouseId={warehouseId}
                  listFieldAuto={listJsonGenerate}
                  handleChange={handleChange}
                  setValues={setValues}
                  values={values}
                  touched={touched}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
              </div>
              <div className="spaces pb-10">
                <Button onClick={e => handleAddRow(values)} className="btn btn-fill spaces mx-3 min-w-100">
                  {indexRowEdit === null ? 'Thêm thuốc/VT' : 'Cập nhật thuốc/VT'}
                </Button>
              </div>
              <OctTable
                className="table-update-vat-tu-phieu-thu custom-header-table"
                headerClasses="headerClasses"
                columns={[...columnActions, ...ColumnThongTinThuNgan]}
                data={listVatTu || []}
                fixedColumnsCount={-1}
                noToolbar
                clearToolbar
                noPagination
              />
              <div className="spaces py-8">
                <Button type="submit" className="btn btn-fill spaces min-w-100 mx-3">Lưu</Button>
              </div>
            </Modal.Body>
          </Form>
        )}
      </Formik>
    </Modal>
  )
};

export default FormUpdateVatTuPhieuThu;