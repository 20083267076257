import { convertNumberPrice, formatMoney } from "../../utils/FormatUtils";

type Props = {
  paymentData?: any;
  children?: any;
  className?: string;
};

const PaymentInfor = ({ paymentData = [], children = "", className="" }: Props) => {
  return (
    <div className={`${className} spaces my-5 ml-10`}>
      <div className="price-info-box spaces mr-50">
        {paymentData?.map((item: any) => {
          return (
            <div className="d-flex align-items-center">
              <div className={`${item?.color} fw-bold white-space-nowrap`}>{item?.name}</div>
              <div className={`${item?.color ? ` ${item?.color} fw-bold` : ''}  spaces py-2 px-8`}>
              {item?.type === "price"
                  ? formatMoney(item?.value)
                  : item?.type === "percent"
                  ? `${item?.value || 0} %`
                  : item?.value || ""}
              </div>
            </div>
          );
        })}
      </div>
      {children}
    </div>
  );
};
export default PaymentInfor;
