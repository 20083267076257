import { WAREHOUSE_ID, WF_TRACKER } from "../../utils/Constant";
import { localStorageItem } from "../../utils/LocalStorage";

const dataUser = localStorageItem.get("current-user") ? localStorageItem.get("current-user") : {};

export const INIT_VALUES_NK = {
  creator: dataUser?.sub,
  dateCreate: new Date(),
  reqWh: "",
  transferWh: "",
  notes: "",
};

export enum TYPE_DIEU_CHUYEN {
  YEU_CAU = 1,
  NHAN_YEU_CAU = 2,
};

export enum STATUS_DIEU_CHUYEN {
  NEW = 1,
  NEW_EXPORT = 10,
  CODE_NEW = "warehouse_export_start",
  WAIT_IMPORT = 3,
  WAIT_APPROVE = 2,
  WAIT_EXPORT = 12,
};

export const LST_WAREHOUSE = {
  2: { reqWhId: WAREHOUSE_ID.VAT_TU, reqWhName: "Kho vật tư", reqWh: "WH02" },
  32: { reqWhId: WAREHOUSE_ID.NHA_THUOC, reqWhName: "Kho nhà thuốc", reqWh: "DR01" },
  79: { reqWhId: WAREHOUSE_ID.NOI_VIEN, reqWhName: "Kho nội viện", reqWh: "DR04" },
  80: { reqWhId: WAREHOUSE_ID.Y_CU, reqWhName: "Kho y cụ", reqWh: "DR05" },
  81: { reqWhId: WAREHOUSE_ID.HOA_CHAT, reqWhName: "Kho hóa chất", reqWh: "DR06" }
};

export const TRANSFER_WAREHOUSE = {
  NOI_VIEN: {
    transferWh: 'DR04',
    transferWhId: WAREHOUSE_ID.NOI_VIEN,
    transferWhName: 'Kho nội viện',
  },
  NHA_THUOC: {
    transferWh: 'DR01',
    transferWhId: WAREHOUSE_ID.NHA_THUOC,
    transferWhName: 'Kho nhà thuốc',
  }
};

export const FILTER_WAREHOUSE = [WAREHOUSE_ID.VAT_TU, WAREHOUSE_ID.Y_CU, WAREHOUSE_ID.HOA_CHAT] // Các kho không điều chuyển sang kho nhà thuốc

export enum CODE_DIEU_CHUYEN {
  NHAP_DIEU_CHUYEN = 'DCK001',
  XUAT_DIEU_CHUYEN = 'DCK002',
}

export const TRANSFER_OPTIONS = [
  {
    name: 'Nhập điều chuyển',
    code: CODE_DIEU_CHUYEN.NHAP_DIEU_CHUYEN,
    tracker: WF_TRACKER.NHAP_DIEU_CHUYEN,
    formThongTinChung: 'phieuDieuChuyenKhoAdd',
    formVatTu: 'formThemMoiVatTuDieuChuyen',
  },
  {
    name: 'Xuất điều chuyển',
    code: CODE_DIEU_CHUYEN.XUAT_DIEU_CHUYEN,
    tracker: WF_TRACKER.XUAT_DIEU_CHUYEN,
    formThongTinChung: 'phieuXuatDieuChuyen',
    formVatTu: 'formThemMoiVatTuDieuChuyen',
  },
]