import { Button, Modal } from "react-bootstrap";
import {
  generateForm,
  generateValidationSchema,
} from "../../utils/AppFunction";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../appContext/AppContext";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import GenerateFormComponent, { ItemChangeProps } from "../../component/generate-form/GenerateFormHTMLNoValid";
import { updateDanhMuc, addDanhMuc } from "../DanhMucServices";
import { CODE } from "../../utils/Constant";
import { toast } from "react-toastify";
import { IconButtonSave } from "../../component/IconSvg";

interface Props {
  show?: boolean;
  listJsonGenerate?:any;
  onHide: () => void;
  initialValues?: any;
  warehouseId?: any;
  type?: any;
  dataEdit?: any;
  item?: any;
}

const DialogThemDanhMuc = (props: Props) => {
  const {
    show,
    onHide,
    item,
    dataEdit,
    warehouseId,
    listJsonGenerate,
    initialValues = {
      code: "",
      name: "",
      taxCode: "",
    },
    type,
  } = props;
  const { setIsLoading } = useContext(AppContext);
  const intl = useIntl();
  const [isView, setIsView] = useState<boolean>();
  // const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);
  const [dataVatTu, setDataVatTu] = useState<any>({});
  const [customFieldReadOnly, setCustomFieldReadOnly] = useState<any>();

  const validationSchemaKH = Yup.object({});

  const validationSchemaFormThemVT = Yup.object({
    nhapKho: Yup.object({}),
  });

  // const updateForm = async () => {
  //   const data = await generateForm(item?.code, setIsLoading);
  //   setListJsonGenerate(data || []);
  // };
  const handleSaveDM = async (values: any) => {
    const dataSubmit = {
      ...values,
      // id: values[item?.code]?.termModelId
    };
    try {
      setIsLoading(true);
      const id = values[item?.code]?.termModelId;
      const { data } = id
        ? await updateDanhMuc(listJsonGenerate?.path, dataSubmit[item?.code])
        : await addDanhMuc(listJsonGenerate?.path, dataSubmit[item?.code]);
      if (CODE.SUCCESS === data?.code) {
        setIsView(true);
        setIsLoading(false);
        toast.success((id ? "Cập nhật" : "Thêm") + ` ${item?.name} thành công`);
        onHide();
      } else {
        let errorMesage =
          data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    }
  };

  // useEffect(() => {
  //   updateForm();
  // }, []);

  useEffect(() => {
    setCustomFieldReadOnly({
      codeExport: isView,
      reason: isView,
      notes: isView,
    });
  }, []);


  const onFieldPropsChange = (data: ItemChangeProps) => {
    // if (data.currentInput === "code" && data.value === "") {
    //   setCustomFieldReadOnly((prev: any) => ({ ...prev, notes: true }));
    // } else {
    //   setCustomFieldReadOnly((prev: any) => ({ ...prev, notes: false }));
    // }
  };

  return (
    <Modal centered show={show} onHide={onHide} size="lg" className=" spaces ">
      <Formik
        enableReinitialize
        initialValues={{ [item?.code]: initialValues }}
        validationSchema={generateValidationSchema(listJsonGenerate, item.code)}
        onSubmit={handleSaveDM}
      >
        {({
          values,
          touched,
          errors,
          setValues,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="">
              <Modal.Header className="header-modal min-h-40px">
                <div className="d-flex gap-6">
                  <div
                    className="d-flex align-items-center title-back gap-3 cursor-pointer"
                    onClick={onHide}
                  >
                    <i className="spaces bi bi-chevron-left ml-10"></i>
                    <div className="spaces mr-20 my-3">Quay lại</div>
                  </div>
                  <div className="spaces mt-4 title">{`Thêm ${item?.name}`}</div>
                </div>
              </Modal.Header>
              <Modal.Body className="spaces p-0 bg-white">
                <div className="spaces px-10">
                  <GenerateFormComponent
                    customFieldReadOnly={customFieldReadOnly}
                    listFieldAuto={listJsonGenerate}
                    itemEdit={dataEdit}
                    handleChange={handleChange}
                    setValues={setValues}
                    values={values}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    onFieldPropsChange={onFieldPropsChange}
                    modelID={item?.code}
                  />
                </div>

                <div className=" d-flex w-100 spaces py-6 pl-5 mt-10">
                  <Button
                    type="submit"
                    className="btn btn-fill min-w-120px spaces ml-5"
                  >
                    <IconButtonSave /> Lưu
                  </Button>
                </div>
              </Modal.Body>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default DialogThemDanhMuc;
