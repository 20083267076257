import { Formik } from "formik";
import * as Yup from "yup";
import { FC, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import TextField from "./TextField";
import LabelRequired from "./LabelRequired";
import { useIntl } from "react-intl";

interface Props {
  show: boolean;
  onHide?: () => void;
  onSubmit?: (values: any) => void;
  title?: string;
  labelSubmit?: string;
  labelClose?: string;
  labelReason?: string;
  className?: string;
}

const EnterReasonDialog: FC<Props> = (props) => {
  const {
    show,
    onHide,
    onSubmit,
    title = "Xác nhận hủy",
    labelSubmit = "Đồng ý",
    labelClose = "Đóng",
    labelReason="Nhập lý do",
    className,
  } = props;

  const intl = useIntl();
  const [initialValues, setInitialValues] = useState({
    reasonForCancel: '',
  });

  const validationSchema = Yup.object({
    reasonForCancel: Yup.string().required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })).nullable(),
  });

  const handleSubmit = (values: any) => {
    onSubmit && onSubmit(values);
  };

  const handleClose = () => {
    onHide && onHide();
    setInitialValues({
      reasonForCancel: '',
    })
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      animation
      className={`background__modal dialog-background ${className}`}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, touched, errors, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header className="header-modal" closeButton>
              <Modal.Title className="text-white text-uppercase">
                {title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="spaces px-10 pt-4 pb-10">
              <div className="">
                <LabelRequired isRequired label={labelReason} />
                <TextField
                  as="textarea"
                  className="min-h-100px max-h-200px"
                  name="reasonForCancel"
                  value={values?.reasonForCancel || ""}
                />
              </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-end p-2 border-top">
              {labelSubmit && (
                <Button className="btn btn-fill min-w-80px" type="submit">
                  {labelSubmit}
                </Button>
              )}
              {labelClose && (
                <Button className="btn-outline min-w-80px" onClick={handleClose}>
                  {labelClose}
                </Button>
              )}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export { EnterReasonDialog };
