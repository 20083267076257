import { Formik } from "formik";
import CustomTabMenu from "../component/CustomTabMenu";
import { danhSachTab } from "./Constant";

export const KhoHoaChat: React.FC = () => {

  return (
    <Formik<any> initialValues={{}} onSubmit={() => {}}>
    <CustomTabMenu
      childrenTab={true}
      danhsachTabs={danhSachTab}
      className="spaces pl-0"
    />
    </Formik>
  );
};

export default KhoHoaChat;
