import { Formik } from "formik";
import { FC, SetStateAction, createContext, useEffect, useRef, useState } from "react";
import { LIST_MENU_TT, OPTIONS_TYPE_TU_TRUC, TreeVatTuTuTruc } from "./constants/TuTrucConstans";
import "./PhanHeTuTruc.scss";
import CustomTabMenu from "../component/CustomTabMenu";
import TonKhoTuTruc from "./components/TonKhoTuTruc";
import BoSungCoSoTuTruc from "./components/BoSungCoSoTuTruc";
import { Col, Row } from "react-bootstrap";
import AutocompleteV2 from "../component/AutocompleteObjectV2";
import { IconMenu } from "../component/IconSvg";
import InputSearch from "../component/InputSearch";
import SelectTree from "../component/SelectTree";
import { getListVatTu } from "./services/TuTrucServices";
import { KEY } from "../utils/Constant";
import NhapBuCoSoTuTruc from "./components/NhapBuCoSoTuTruc/NhapBuCoSoTuTruc";
import XuatSuDung from "./components/XuatSuDung";

export interface PhanHeTuTrucContextProps {
  tuTrucCurrent: object;
  dsVatTu: any;
  setDsVatTu: SetStateAction<any>;
}

const initialContext = {
  tuTrucCurrent: {},
  dsVatTu: [],
  setDsVatTu: () => {},
};

export const PhanHeTuTrucContext =
  createContext<PhanHeTuTrucContextProps>(initialContext);

export const PhanHeTuTruc: FC = () => {
  const [dsVatTu, setDsVatTu] = useState([]);
  const [tuTrucCurrent, setTuTrucCurrent] = useState<any>({} as any);
  const [typeTuTruc, setTypeTuTruc] = useState(OPTIONS_TYPE_TU_TRUC[0]);
  const [codeCollapses, setCodeCollapses] = useState<string[]>([]);
  const [idSelected, setIdSelected] = useState<string>("");
  const [treeData, setTreeData ]= useState<any>(TreeVatTuTuTruc);
  const [keySearchTuTruc, setKeySearchTuTruc] = useState('');
  const [isSearchListVT, setIsSearchListVT] = useState(false);
  const [paramsVT, setParamsVT] = useState({
    pageSize: 20,
    pageIndex: 1
  })

  useEffect(() => {
    setTreeData({ ...TreeVatTuTuTruc, filter: TreeVatTuTuTruc.filter.filter((item: any) => item?.code === typeTuTruc?.code) })
    getListVT({ ...paramsVT, level: typeTuTruc.code === OPTIONS_TYPE_TU_TRUC[0].code ? 2 : 3 }) // 2 - thuốc; 3 - vật tư
  }, [typeTuTruc])

  useEffect(() => {
    if(!isSearchListVT) return;
    getListVT({ ...paramsVT, keySearch: keySearchTuTruc, level: typeTuTruc.code === OPTIONS_TYPE_TU_TRUC[0].code ? 2 : 3 }) // 2 - thuốc; 3 - vật tư
  }, [isSearchListVT])

  const getListVT = async (params: any) => {
    let res = await getListVatTu(params)
    setTreeData((prev: any) => ({ ...prev, filter: [{ ...prev.filter[0], filter: res?.data?.data?.content }] }))
  }

  const danhSachTab = [
    {
      eventKey: "0",
      title: "Xem tồn kho",
      component: <TonKhoTuTruc />,
    },
    {
      eventKey: "1",
      title: "Nhập bù cơ số tủ trực",
      component: <NhapBuCoSoTuTruc modelId={LIST_MENU_TT(typeTuTruc.code)[3]}/>,
    },
    {
      eventKey: "2",
      title: "Xuất bù cơ số tủ trực",
      component: <></>,
    },
    {
      eventKey: "3",
      title: "Lĩnh về tủ trực",
      component: <BoSungCoSoTuTruc modelId={LIST_MENU_TT(typeTuTruc.code)[2]} />,
    },
    // {
    //   eventKey: "4",
    //   title: "Phiếu lĩnh",
    //   component: <DanhSachPhieuTuTruc modelId={LIST_MENU_TT(typeTuTruc.code)[0]} />,
    // },
    {
      eventKey: "5",
      title: "Xuất sử dụng",
      component: <XuatSuDung modelId={LIST_MENU_TT(typeTuTruc.code)[1]} />,
    },
    {
      eventKey: "6",
      title: "Xuất bệnh nhân",
      component: <></>,
    },
  ];

  const handleGetSelectedItem = (data: any) => setTuTrucCurrent({ ...data, whReceiveName: data.name, whReceiveCode: data.code })

  useEffect(() => {}, []);
  return (
    <Formik<any> initialValues={{}} onSubmit={() => {}}>
      <PhanHeTuTrucContext.Provider
        value={{
          tuTrucCurrent,
          dsVatTu,
          setDsVatTu,
        }}
      >
        <Row className="g-0 bg-white">
          <Col className="pt-2 px-2" xs={2} md={2} lg={2} xl={2}>
            <AutocompleteV2
              options={OPTIONS_TYPE_TU_TRUC}
              isSearchDefauilt={true}
              name={"WmsCode"}
              onChange={(options: any) => setTypeTuTruc(options)}
              value={typeTuTruc}
              className="autocomplete-custom-renderform  radius spaces width-100 h-29 text-center mb-4"
              isClearable={false}
            />
            <h4 className="text-title text-pri fw-bold fs-4 spaces custom-title my-auto">
              <IconMenu /> {`Danh sách tủ trực`}
            </h4>
            <InputSearch
              name="keyword"
              value={keySearchTuTruc}
              placeholder="Tìm kiếm"
              handleChange={(e) => {setKeySearchTuTruc(e.target.value); setIsSearchListVT(false)}}
              handleSearch={() => setIsSearchListVT(true)}
              handleKeyDown={(e: React.KeyboardEvent) =>{
                e.key === KEY.ENTER && setIsSearchListVT(true)
              }}
              className="spaces h-31 pr-4  radius-3"
            />
            <SelectTree
              className="w-100 h-100 border  px-0  border-0 overflow-auto"
              codeCollapses={codeCollapses}
              handleChangeCollapsesCode={setCodeCollapses}
              idSelected={idSelected}
              handleChangeSelectId={setIdSelected}
              getSelectedItem={handleGetSelectedItem}
              selectTree={treeData}
            />
          </Col>
          <Col xs={10} md={10} lg={10} xl={10}>
            <CustomTabMenu
              danhsachTabs={danhSachTab}
              className="spaces pl-0"
            />
          </Col>
        </Row>
      </PhanHeTuTrucContext.Provider>
    </Formik>
  );
};

export default PhanHeTuTruc;
