import axios, { AxiosResponse } from "axios";
import { ReactNode, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import FormInPhieuDialog from "./components/PrintDialog";
import CustomIconButton from "../custom-icon-button/CustomIconButton";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
import { IInPhieuParams } from "../../models/params";

type ButtonInProps<T> = {
  label?: ReactNode;
  fetchExport?: (params: T) => Promise<AxiosResponse<Blob, any>>;
  params: T;
  className?: string;
  keyOpenPhieuIn?: string;
  setKeyOpenPhieuIn?: (data: string) => void;
  isIcon?: boolean;
  endpoint?: string;
};

type AxiosRequestType<T> = (params: T) => Promise<AxiosResponse<Blob, any>>;

const ButtonIn = <T extends object>({
  label = "In phiếu",
  fetchExport = (async () => Promise.reject(new Error("No fetchExport function provided"))),
  params,
  className,
  keyOpenPhieuIn,
  setKeyOpenPhieuIn,
  isIcon = false,
  endpoint,
}: ButtonInProps<T>) => {
  const [openPrintDialog, setOpenPrintDialog] = useState(false);
  const [axiosReq, setAxiosReq] = useState<AxiosRequestType<T>>(() => fetchExport);

  useEffect(() => {
    keyOpenPhieuIn && setOpenPrintDialog(true);
    setKeyOpenPhieuIn?.("");
  }, [keyOpenPhieuIn]);

  useEffect(() => {
    if (!endpoint) return;
    const API = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_WMS_API_URL;
    const url = `${API}/${endpoint}`;
    const fetchApi = (params: IInPhieuParams) => {
      return axios.post<Blob>(url, params, { responseType: 'blob' });
    };
    setAxiosReq(() => fetchApi);
  }, [endpoint]);

  return (
    <>
      {!isIcon && (
        <Button
          className={`btn-fill ${className}`}
          onClick={() => setOpenPrintDialog(true)}
        >
          {label}
        </Button>
      )}
      {isIcon && (
        <CustomIconButton
          variant="print"
          onClick={() => setOpenPrintDialog(true)}
        >
          <i className="bi bi-printer text-navy"></i>
        </CustomIconButton>
      )}
      {openPrintDialog && (
        <FormInPhieuDialog<T>
          show={openPrintDialog}
          onHide={() => setOpenPrintDialog(false)}
          fetchExport={axiosReq}
          params={params}
        />
      )}
    </>
  );
};

export default ButtonIn;
