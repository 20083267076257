import { Button, Modal } from "react-bootstrap";
import { generateForm } from "../../utils/AppFunction";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../appContext/AppContext";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import GenerateFormComponent from "../../component/generate-form/GenerateFormHTMLNoValid";
import { CODE } from "../../utils/Constant";
import { toast } from "react-toastify";
import { IconButtonSave } from "../../component/IconSvg";
import { addPhieuKiemKe, downloadInv, printPhieuKiemKe, updatePhieuKiemKe } from "../KiemKeServices";
import { useSortBy } from "react-table";
import { formatStringDateDTO } from "../../utils/FormatUtils";
import ButtonIn from "../../component/button-in-phieu";
import { localStorageItem } from '../../../../app/modules/utils/LocalStorage'


interface Props {
  show?: boolean;
  onHide: () => void;
  initialValues?: any;
  // setInitialValues?: Dispatch<SetStateAction<any>>
  warehouseId?: any;
  type?: any;
  dataEdit?: any;
  searchObj?: any;
}

const DialogKiemKe = (props: Props) => {
  const {
    show,
    onHide,
    searchObj,
    dataEdit,
    warehouseId,
    initialValues = {},
    type,
  } = props;
  const { setIsLoading } = useContext(AppContext);
  const intl = useIntl();
  const [isView, setIsView] = useState<boolean>();
  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);
  const [dataVatTu, setDataVatTu] = useState<any>({});
  const [customFieldReadOnly, setCustomFieldReadOnly] = useState<any>();
  const [defauiltValue, setDefauiltValue] = useState<any>(initialValues);
  const validationSchema = Yup.object({});
  const orgname = localStorageItem.get("id-token-decode")?.orgname

  useEffect(() => {
    setDefauiltValue({
      ...initialValues,
      phieuKiemKe: {
        ...initialValues?.phieuKiemKe,
        ...dataEdit,
        doCreation: dataEdit?.doCreation || new Date()
      }
    })
  }, [dataEdit])
  const updateForm = async () => {
    const data = await generateForm("phieuKiemKe", setIsLoading);
    setListJsonGenerate(data || []);
  };
  const handleSaveDM = async (values: any) => {
    const dataSubmit = {
      slipDto: {
        ...values?.phieuKiemKe,
        type: 13,
        doCreation: formatStringDateDTO(values?.phieuKiemKe?.doCreation),
        warehouseId: searchObj?.warehouseId,
      },
      lstItem: null,
      id: values?.phieuKiemKe?.id,
    };
    try {
      setIsLoading(true);
      const id = values?.phieuKiemKe?.id;
      const { data } = id
        ? await updatePhieuKiemKe(dataSubmit, "phieuKiemKe")
        : await addPhieuKiemKe(dataSubmit, "phieuKiemKe");
      if (CODE.SUCCESS === data?.code) {
        setIsView(true);
        setIsLoading(false);
        toast.success((id ? "Cập nhật" : "Thêm") + " phiếu kiểm kê thành công");
        onHide();
      } else {
        let errorMesage =
          data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    }
  };

  useEffect(() => {
    updateForm();
  }, []);

  useEffect(() => {
    setCustomFieldReadOnly({
      codeExport: isView,
      notes: isView,
    });
  }, [isView]);
  const handleDownload = async () => {
    try {
      setIsLoading(true);
      const response = await downloadInv(
        {
          parameters: [
            { name: "benh_vien", value: orgname || "" },
            { name: "warehouse_id", value: searchObj?.warehouseId },
            { name: "tu_ngay", value: dataEdit?.doCreateFrom },
            { name: "den_ngay", value: dataEdit?.doCreateTo },
            { name: "person_1_name", value: dataEdit?.person1Name },
            { name: "person_2_name", value: dataEdit?.person2Name },
            { name: "person_3_name", value: dataEdit?.person3Name },
            { name: "person_4_name", value: dataEdit?.person4Name },
            { name: "person_5_name", value: dataEdit?.person5Name },
            { name: "person_6_name", value: dataEdit?.person6Name },
            { name: "person_7_name", value: dataEdit?.person7Name },
          ]
        },
      );
      const blob = new Blob([response.data], { type: 'application/xls' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `excel.xls`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal centered show={show} onHide={onHide} size="lg" className=" spaces">
      <Formik
        enableReinitialize
        initialValues={defauiltValue}
        validationSchema={validationSchema}
        onSubmit={handleSaveDM}
      >
        {({
          values,
          touched,
          errors,
          setValues,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className="">
                <Modal.Header className="header-modal min-h-40px">
                  <div className="d-flex gap-6">
                    <div
                      className="d-flex align-items-center title-back gap-3 cursor-pointer"
                      onClick={onHide}
                    >
                      <i className="spaces bi bi-chevron-left ml-10"></i>
                      <div className="spaces mr-20 my-3">Quay lại</div>
                    </div>
                    <div className="spaces mt-4 title">{`${dataEdit?.id ? "Cập nhật" : "Thêm"} phiếu kiểm kê`}</div>
                  </div>
                </Modal.Header>
                <Modal.Body className="spaces p-0 bg-white">
                  <div className="spaces px-10">
                    <GenerateFormComponent
                      // isView={isView || values.nhapKho.codeExport}
                      // customFieldReadOnly={customFieldReadOnly}
                      listFieldAuto={listJsonGenerate}
                      itemEdit={dataEdit}
                      // handleChange={handleChange}
                      setValues={setValues}
                      values={values}
                      touched={touched}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      modelID={"phieuKiemKe"}
                    />
                  </div>

                  <div className=" d-flex justify-content-end w-100 spaces py-6 pr-5 mt-10">
                    <Button
                      onClick={() => handleSaveDM(values)}
                      className="btn btn-fill min-w-120px spaces ml-5"
                    >
                      <IconButtonSave /> Lưu
                    </Button>
                    {/* <ButtonIn
                      className="spaces min-w-120 mx-3"
                      fetchExport={printPhieuKiemKe}
                      params={{
                        parameters: [
                          { name: "benh_vien", value: "BỆNH VIỆN ĐA KHOA CHỮ THẬP XANH" },
                          { name: "warehouse_id", value: searchObj?.warehouseId },
                          { name: "tu_ngay", value: dataEdit?.doCreateFrom },
                          { name: "den_ngay", value: dataEdit?.doCreateTo },
                          { name: "person_1_name", value: dataEdit?.person1Name },
                          { name: "person_2_name", value: dataEdit?.person2Name },
                          { name: "person_3_name", value: dataEdit?.person3Name },
                          { name: "person_4_name", value: dataEdit?.person4Name },
                          { name: "person_5_name", value: dataEdit?.person5Name },
                          { name: "person_6_name", value: dataEdit?.person6Name },
                          { name: "person_7_name", value: dataEdit?.person7Name },
                        ]
                      }}
                    /> */}
                    {dataEdit?.id &&
                      <Button
                        onClick={() => handleDownload()}
                        className="btn btn-fill min-w-120px spaces ml-5"
                      >
                        Tải về
                      </Button>
                    }
                  </div>
                </Modal.Body>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default DialogKiemKe;
