import { Formik } from "formik";
import { Dispatch, FC, SetStateAction, createContext, useState } from "react";
import FormQuanLyThau from "./components/FormQuanLyThau";

export interface QuanLyThauContextProps {
  // dsVatTu: any;
  // setDsVatTu: Dispatch<SetStateAction<any[]>>;
  detailGoiThau: any;
  setDetailGoiThau: Dispatch<SetStateAction<any>>;
};

const initialContext = {
  // dsVatTu: [],
  // setDsVatTu: () => { },
  detailGoiThau: {},
  setDetailGoiThau: () => { },
};

export const QuanLyThauContext = createContext<QuanLyThauContextProps>(initialContext);

export const QuanLyThau: FC = () => {
  // const [dsVatTu, setDsVatTu] = useState<any[]>([]);
  const [detailGoiThau, setDetailGoiThau] = useState<any>();

  return (
    <Formik<any> initialValues={{}} onSubmit={() => { }}>
      <QuanLyThauContext.Provider
        value={{
          // dsVatTu,
          // setDsVatTu,
          detailGoiThau,
          setDetailGoiThau,
        }}
      >
        <div className="bg-white">
          <div className="flex-1 flex flex-column">
            <div className="py-1 ">
              <FormQuanLyThau />
            </div>
          </div>
        </div>
      </QuanLyThauContext.Provider>
    </Formik>
  );
};

export default QuanLyThau;
