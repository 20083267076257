import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { WfSummaryStatusRes } from '../models/WfWorkflows'

type Props = {
    summaryStatus: WfSummaryStatusRes[],
    onClick?: (data: WfSummaryStatusRes) => void,
    col?: number,
    hiddenQuantity?: boolean, 
}

const WfStatusSummary = (props: Props) => {
    const { summaryStatus, onClick, col } = props;
    return (
        <div className="spaces mx-12">
            <Row className="d-flex spaces w-100 mx-0">
                {summaryStatus.map((item, index) => (
                    <Col key={index} xs={col ? col : 6}>
                        <div
                            onClick={() => onClick?.(item)} 
                            style={{ backgroundColor: item.color }}
                            className={`status-${item.color}-bg text-center text-white cursor-pointer rounded spaces px-8 py-4 mb-8`}>
                            {props.hiddenQuantity
                                ? <span className="body-normal-1">{`${item.statusName}`}</span>
                                : <span className="body-normal-1">{`${item.statusName}: ${item.summary}`}</span>
                            }
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
    )
}

export default WfStatusSummary