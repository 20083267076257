import { ReactNode, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import LabelRequired from "./LabelRequired";
import TextField from "./TextField";
import InputSearch from "./InputSearch";

type TFilterTree = {
  name: string;
  code: string;
  quantity?: number;
  icon?: ReactNode;
};

type TTree = {
  code: string;
  name: string;
  filter?: TFilterTree[];
  icon?: ReactNode;
  quantity?: number;
};

type TSelectTree = {
  code?: string;
  name?: string;
  filter: TTree[];
  icon?: ReactNode;
  quantity?: number;
};

type TProps = {
  className?: string;
  codeCollapses: string[];
  handleChangeCollapsesCode: React.Dispatch<React.SetStateAction<string[]>>;
  idSelected: string;
  handleChangeSelectId: React.Dispatch<React.SetStateAction<string>>;
  selectTree: TSelectTree;
  onClickIcon?: boolean | null;
  getSelectedItem?: (data: any) => void;
  isSearchExpanded?: boolean | null;
  countStatus?: any;
};

const initialValues: any = {
  keyword: "",
  toDate: null,
  fromDate: null,
};

const SelectTree = ({
  codeCollapses,
  handleChangeCollapsesCode,
  idSelected,
  handleChangeSelectId,
  selectTree,
  className,
  onClickIcon,
  getSelectedItem,
  isSearchExpanded,
  countStatus,
}: TProps) => {
  const [filter, setFilter] = useState<any>();

  const handleCollapse = (code: string) => {
    if (codeCollapses.includes(code)) {
      handleChangeCollapsesCode(
        codeCollapses.filter((item: string) => item !== code)
      );
    } else {
      handleChangeCollapsesCode([...codeCollapses, code]);
    }
  };

  useEffect(() => {
    handleChangeCollapsesCode([
      selectTree?.code,
      ...selectTree?.filter?.map((item: any) => item.code),
    ]);
  }, []);

  const handleSelected = (code: string): void => {
    if (idSelected === code) {
      handleChangeSelectId("");
    } else {
      handleChangeSelectId(code);
    }
  };

  const handleSearch = (values: any) => { };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      const form = document.getElementById("form-vien-phi") as HTMLFormElement;
      form.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  };

  return (
    <div
      className={`tree border border-left-0 ${className} p-2 position-relative`}
    >
      {isSearchExpanded && (
        <div>
          <Row>
            <Col xs={12}>
              <Formik initialValues={initialValues} onSubmit={handleSearch}>
                {({
                  values,
                  handleSubmit,
                  setFieldValue,
                  errors,
                  touched,
                  resetForm,
                }) => (
                  <Form id="form-vien-phi" onSubmit={handleSubmit}>
                    <div className="d-flex">
                      <LabelRequired label="Từ ngày" className="spaces flex-2" />
                      <TextField
                        className="spaces flex-4"
                        name="fromDate"
                        type="date"
                        disabled={false}
                        onChange={(e: any) => {
                          setFilter({
                            ...filter,
                            fromDate: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="d-flex my-3">
                      <LabelRequired label="Đến ngày" className="spaces flex-2" />
                      <TextField
                        className="spaces flex-4"
                        name="toDate"
                        type="date"
                        disabled={false}
                        onChange={(e: any) => {
                          setFilter({
                            ...filter,
                            toDate: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="spaces mb-3">
                      <InputSearch
                        name="keyword"
                        // size="sm"
                        placeholder="Tìm kiếm"
                        // value=""
                        handleChange={(e) => {
                          setFilter({
                            ...filter,
                            keyword: e.target.value,
                          });
                        }}
                        className="py-5 h-32"
                        onKeyPress={handleKeyPress}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </div>
      )}
      {selectTree.code && (
        <div
          onClick={() => handleCollapse(selectTree.code ?? "")}
          className="d-flex align-items-center ms-4 pb-2"
        >
          {!selectTree?.icon && (
            <span>
              {codeCollapses.includes(selectTree.code ?? "") ? (
                <i className="cursor-pointer bi bi-caret-down-fill text-black spaces pr-10"></i>
              ) : (
                <i className="cursor-pointer bi bi-caret-right-fill text-black spaces pr-10"></i>
              )}
            </span>
          )}
          <span className="fw-bold d-flex truncate-text text-pri">
            {selectTree?.icon && (
              <span className="spaces pr-10 d-flex align-items-center text-pri">
                {selectTree?.icon}
              </span>
            )}{" "}
            {selectTree.name}
          </span>
        </div>
      )}
      {(selectTree.code ? codeCollapses.includes(selectTree.code) : true) && selectTree.filter?.length &&
        selectTree?.filter?.map((item) => (
          <div key={item?.code}>
            <div
              className={`${selectTree.code ? "pl-13 spaces" : "fw-bold"
                } d-flex row-tree fw-bold text-gray pb-2`}
              onClick={() => !onClickIcon && handleCollapse(item.code)}
            >
              <span>
                {
                  // item?.filter && item?.filter?.length > 0 &&
                  <div onClick={() => onClickIcon && handleCollapse(item.code)}>
                    {codeCollapses.includes(item.code) ? (
                      <i className="cursor-pointer bi bi-caret-down-fill text-black spaces pr-12"></i>
                    ) : (
                      <i className="cursor-pointer bi bi-caret-right-fill text-black spaces pr-12"></i>
                    )}
                  </div>
                }
              </span>
              <span
                className="cursor-pointer truncate-text d-flex"
                onClick={() => handleChangeSelectId(item.code)}
              >
                {item?.icon && (
                  <span className="spaces pr-10 d-flex align-items-center">
                    {item?.icon}
                  </span>
                )}
                {item.name}
              </span>
            </div>
            {codeCollapses.includes(item.code) &&
              item?.filter?.map((i) => (
                <div
                  key={i?.code}
                  className={
                    idSelected?.includes(i.code)
                      ? `isFilterSelected ${item?.icon ? "pl-38" : "pl-23"
                      } spaces w-100 truncate-text d-flex align-items-center pb-4`
                      : `${item?.icon ? "pl-38" : "pl-23"
                      } spaces w-90 hover-row truncate-text d-flex align-items-center cursor-pointer pb-4`
                  }
                  onClick={() => {
                    handleSelected(i.code);
                    getSelectedItem && getSelectedItem(i);
                  }}
                >
                  <span className="truncate-text spaces pl-10 row-tree">
                    {i.name}
                  </span>{" "}
                  &nbsp;
                  {i.quantity ? (
                    <span className="quantity">{i.quantity}</span>
                  ) : (
                    item?.icon && <span className="spaces W-10">{i?.icon}</span>
                  )}
                </div>
              ))}
          </div>
        ))}
      <div className="position-absolute bottom-0 px-2 left-14 w-89">
        <Row>
          {countStatus &&
            countStatus.map((item: any) => {
              return (
                <Col
                  key={item?.code}
                  xs={12}
                  className={`d-flex flex-center ${item?.className} mt-5`}
                >
                  {item?.name} : {item?.count}
                </Col>
              );
            })}
        </Row>
      </div>
    </div>
  );
};

export default SelectTree;
