import { Column } from "react-table";
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import { TableCustomCell } from "../../component/table/components/TableCustomCell";
import { formatDateToDDMMYYYY,formatDateAdvanceToString, formatMoney } from "../../utils/FormatUtils";

export { };

export const columnsDSVatTu: ReadonlyArray<Column<any>> = [
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="STT"
                className="text-center text-light max-w-35"
            />
        ),
        id: "stt",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-center "
                data={String(props?.row?.index + 1)}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Thời gian"
                className="text-white text-center align-middle min-w-200px"
            />
        ),
        id: "doCreation",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-center"
                data={formatDateAdvanceToString(props.data[props.row.index]?.doCreation)}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Phiếu nhập"
                className=" text-center text-white align-middle bg-pri min-w-100px"
            />
        ),
        id: "importCode",
        Cell: ({ ...props }) => (
            <TableCustomCell
                data={props.data[props.row.index]?.importCode}
                className="text-left"
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Phiếu xuất"
                className=" text-center text-white align-middle bg-pri min-w-100px"
            />
        ),
        id: "exportCode",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="s-2"
                data={props.data[props.row.index]?.exportCode}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Số lô"
                className="text-center text-white align-middle bg-pri min-w-80px"
            />
        ),
        id: "consignment",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="s-2"
                data={props.data[props.row.index]?.consignment}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Hạn dùng"
                className=" text-center text-white align-middle bg-pri min-w-80px"
            />
        ),
        id: "expiryDate",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-end s-2"
                data={formatDateToDDMMYYYY(props.data[props.row.index]?.expiryDate)}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Diễn giải"
                className="text-center text-white align-middle bg-pri min-w-200px"
            />
        ),
        id: "description",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-end s-2"
                data={props.data[props.row.index]?.description || 0}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="SL đầu kỳ"
                className="text-center text-white align-middle bg-pri min-w-100px"
            />
        ),
        id: "totalFirst",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-end s-2"
                data={props.data[props.row.index]?.totalFirst || 0}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="SL nhập"
                className="text-center text-white align-middle bg-pri min-w-100px"
            />
        ),
        id: "importToTal",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-end s-2"
                data={props.data[props.row.index]?.importToTal}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="SL xuất"
                className="text-center text-white align-middle bg-pri min-w-100px"
            />
        ),
        id: "exportToTal",
        Cell: ({ ...props }) => (
            <TableCustomCell
                 className="text-end s-2"
                data={props.data[props.row.index]?.exportToTal}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="SL tồn"
                className="text-center text-white align-middle bg-pri min-w-100px"
            />
        ),
        id: "inventory",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-end s-2"
                data={props.data[props.row.index]?.inventory || 0}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Đơn giá"
                className="text-center text-white align-middle bg-pri min-w-100px"
            />
        ),
        id: "sellingPrice",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-end s-2"
                data={formatMoney(props.data[props.row.index]?.sellingPrice) || ''}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Thành tiền"
                className="text-center text-white align-middle bg-pri min-w-130px"
            />
        ),
        id: "totalCost",
        Cell: ({ ...props }) => {
            const row = props.data[props.row.index] || {};
            return (
                <TableCustomCell
                    className="text-end s-2"
                    data={props.data[props.row.index]?.totalCost}
                />
            )
        }
    },
];