import { Formik } from "formik";
import { FC, SetStateAction, createContext, useEffect, useState } from "react";
import FormNhapKho from "./components/FormNhapKho";
import * as Yup from "yup";



export interface PhanHeNhapKhoContextProps {
    dsNhapKho: any;
    setDsNhapKho: SetStateAction<any>;
    detailPhieuNhap: any;
    setDetailPhieuNhap: SetStateAction<any>;

}

const initialContext = {
    dsNhapKho: [],
    setDsNhapKho: () => { },
    detailPhieuNhap: [],
    setDetailPhieuNhap: () => { },
}

export const PhanHeNhapKhoContext = createContext<PhanHeNhapKhoContextProps>(initialContext)
interface Props {
    warehouseId: number;
}
export const PhanHeNhapKho = (props: Props) => {
    const { warehouseId } = props;
    const [dsNhapKho, setDsNhapKho] = useState([]);
    const [detailPhieuNhap, setDetailPhieuNhap] = useState({});

    useEffect(() => {
    }, []);

    let validationSchema = Yup.object({
        // thongTinChung: Yup.object({
        //   MaterialCode: Yup.string()
        //     .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable()
        // })
    })
    const handleFormSubmit = (values: any) => {}
    return (
        <Formik<any>
            initialValues={{}}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
        >
            <PhanHeNhapKhoContext.Provider
                value={{
                    dsNhapKho: dsNhapKho,
                    setDsNhapKho: setDsNhapKho,
                    detailPhieuNhap,
                    setDetailPhieuNhap,
                }}
            >
                <div className="bg-white">

                    <div className="flex-1 flex flex-column">
                        <div className="py-1">
                            <FormNhapKho warehouseId={warehouseId}
                            />
                        </div>
                    </div>


                </div>

            </PhanHeNhapKhoContext.Provider>
        </Formik>

    )
}

export default PhanHeNhapKho;
