import { columnNamesType, OctTable } from "@oceantech/oceantech-ui";
import CustomIconButton from "../../../component/custom-icon-button/CustomIconButton";
import { formatDateToDDMMYYYY, formatMoney } from "../../../utils/FormatUtils";
import { Dispatch, SetStateAction } from "react";

type Props = {
  dsVatTu: any;
  setDsVatTu: Dispatch<SetStateAction<any>>
  setVatTuSelected: Dispatch<SetStateAction<any>>
};

const ThongTinXuatVatTu = ({dsVatTu = [], setDsVatTu, setVatTuSelected }: Props) => {

  const handleDeleteRow = (index: number) => {
    const newDsVatTu = [...dsVatTu];
    if (index >= 0 && index < newDsVatTu.length) {
      newDsVatTu.splice(index, 1);
    };

    setDsVatTu(newDsVatTu);
  };

  const handleEditRow = (row: any, index?: any) => {
    const data = row.item
    const dataRow = {
      item: data.code,
      itemId: data.id,
      itemName: data.name,
      name: data.name,
      code: data.code,
      iventory: data.iventory || '',
      availableQuantity: data.availableQuantity || '',
      sellingPrice: data.sellingPrice || '',
      hospitalPrice: data.hospitalPrice || '',
      importPrice: data.importPrice || '',
      packaging: data.packaging || '',
      unitId: data.unitofMeasureName,
      unitofMeasureName: data.unitofMeasureName,
      concentration: data.concentration,
      dosage: data.dosage,
      countryofOrigin: data.countryofOrigin,
      manufacturer: data.manufacturer,
      totalQuantity: row.totalQuantity,
      vat: data.vat,
      registrationNumber: data.registrationNumber,
      consignment: data.consignment || '',
      expiryDate: data.expiryDate,
      usageIns: data.usageIns || '',
      dataItem: data,
      index: index,
    };
    setVatTuSelected(dataRow);
  };

  let columns: columnNamesType[] = [
    {
      name: "STT",
      field: "stt",
      headerStyle: {
        width: "50px"
      },
      cellStyle: {
        textAlign: "center"
      },
      render: (row, index, stt) => {
        return stt
      },
    },
    {
      name: 'TT',
      field: 'custom',
      headerStyle: {
        width: 50,
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (row, index, stt) => {
        return (
          <div className="d-flex gap-5">
            <CustomIconButton
              variant="delete"
              onClick={() => handleDeleteRow(index)}
            >
              <i className="bi bi-trash3-fill text-danger"></i>
            </CustomIconButton>
            <CustomIconButton
              variant="edit"
              onClick={() => handleEditRow(row, index)}
            >
              <i className="bi bi-pencil-square text-navy"></i>
            </CustomIconButton>
          </div>
        )
      }
    },
    {
      name: "Mã thuốc",
      field: "code",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row, index, stt) => row?.item?.code
    },
    {
      name: "Tên thuốc",
      field: "item",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row, index, stt) => row.item?.name,
    },
    {
      name: "Số lượng",
      field: "totalQuantity",
      headerStyle: {
        minWidth: "80px",
      },
      cellStyle: {
        textAlign: 'end',
      },
      render: (row, index, stt) => formatMoney(row?.totalQuantity) || "",
    },
    {
      name: "Đơn vị",
      field: "unitofMeasureName",
      headerStyle: {
        minWidth: "105px"
      },
      render: (row, index, stt) => row?.item?.unitofMeasureName,
    },
    {
      name: "SL khả dụng",
      field: "availableQuantity",
      headerStyle: {
        minWidth: "100px"
      },
      cellStyle: {
        textAlign: 'end',
      },
      render: (row, index, stt) => formatMoney(row?.item?.availableQuantity),
    },
    {
      name: "Giá bán",
      field: "hospitalPrice",
      headerStyle: {
        minWidth: "100px"
      },
      cellStyle: {
        textAlign: 'end',
      },
      render: (row, index, stt) => formatMoney(row?.item?.hospitalPrice),
    },
    {
      name: "% VAT",
      field: "phanTramVAT",
      headerStyle: {
        minWidth: "60px"
      },
      cellStyle: {
        textAlign: 'end',
      },
      render: (row, index, stt) => row?.item?.vat || 0,
    },
    {
      name: "Thành tiền",
      field: "thanhTien",
      headerStyle: {
        minWidth: "100px"
      },
      cellStyle: {
        textAlign: 'end',
      },
      render: (row, index, stt) => {
        const quantity = parseFloat(row?.totalQuantity || 0);
        const price = parseFloat(row?.item?.hospitalPrice || 0);
        const vat = parseFloat(row?.item?.vat || 0);
        const result = (quantity * price * (1 + vat / 100));
        return formatMoney(result);
      },
    },
    {
      name: "Số lô",
      field: "consignment",
      headerStyle: {
        minWidth: "75px"
      },
      render: (row, index, stt) => row?.item?.consignment,
    },
    {
      name: "HSD",
      field: "expiryDate",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row, index, stt) => formatDateToDDMMYYYY(row?.item?.expiryDate),
    },
  ]
  
  return (
    <div className="bg-white spaces mb-6 pb-15 fl-1">
      <OctTable
        id="h-bang-ds-xuat-thuoc"
        className="spaces table-bang-ds-xuat-thuoc"
        columns={columns}
        data={dsVatTu}
        clearToolbar
        noToolbar
        noPagination
      />
    </div>
  );
};

export default ThongTinXuatVatTu;
