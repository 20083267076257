import axios from "axios";
import { localStorageItem } from './../../utils/LocalStorage';
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";

const API = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_WMS_API_URL;

export const searchVatTuXuatKho = (searchData: any) => {
  const url = `${API}/item/search`;
  return axios.post(url, searchData);
};

export const searchVatTuNhapKho = (params: any) => {
  const url = `${API}/warehouse/search-inventory-tem`;
  return axios.get(url, { params: params});
};