import { localStorageItem } from "../../../utils/LocalStorage";
const dataUser = localStorageItem.get("current-user") || {};

export const INITIAL_VALUES = {
  phieuXuatKhoAdd: {
    patientName: '',
    patientGender: '',
    age: '',
    guardian: '',
    guardianPhoneNumber: '',
    mateWh: "DR01",
    mateWhName: "Kho nhà thuốc",
    mateWhId: 32,
    doCreation: new Date(),
    creator: dataUser?.name,
  },
  dsVatTu: [],
}