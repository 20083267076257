import { Button } from 'react-bootstrap';
import LabelRequired from '../../LabelRequired';
import { EXPORT_TYPE } from '../constant';

type PrintActionProps = {
    onPrint: () => void;
    onExport: (type: string) => void;
};

const PrintAction = ({ onPrint, onExport }: PrintActionProps) => {
    return (
        <div>
            <div className="d-flex mb-4">
                <Button
                    variant="outline-primary"
                    size="lg"
                    className="border border-primary w-100 h-100px"
                    onClick={onPrint}
                >
                    <i className="bi bi-printer fs-lg-2hx"></i>
                    <span>In</span>
                </Button>
            </div>

            <div className="mt-8">
                <LabelRequired label="Xuất File" />
                <div className="d-flex">
                    <Button
                        variant="outline-primary"
                        size="lg"
                        className="border border-primary"
                        onClick={() => onExport(EXPORT_TYPE.PDF)}
                    >
                        <i className="bi bi-filetype-pdf fs-1"></i>
                        <span>Pdf</span>
                    </Button>
                    <Button
                        variant="outline-primary"
                        size="lg"
                        className="border border-primary ms-2"
                        onClick={() => onExport(EXPORT_TYPE.DOC)}
                    >
                        <i className="bi bi-filetype-doc fs-1"></i>
                        <span>Word</span>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default PrintAction;
