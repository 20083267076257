import { TYPE_WH, WF_TRACKER } from "../../utils/Constant";

export const WMS_STATUS = {
  warehouse_approved: "warehouse_approved",
  NEW: 10,
};

export enum EXPORT_CODE {
  KHACH_HANG = "X002",
  NCC = "X001",
  KHAC = "X006",
  HUY = "X007",
  HAO_PHI = "X008",
}

export const EXPORT_FORM_CODE = {
  GENERAL: {
    NCC: 'themMoiPhieuXuatKhoNCC',
    KHACH_HANG: 'themMoiPhieuXuatKhoKH',
    KHAC: 'phieuXuatKhac',
    HUY: "formXuatHuy",
    HAO_PHI: "phieuXuatHaoPhi",
  },
  VAT_TU: {
    NCC: 'formThemMoiVatTuXuatKhoNCC',
    KHACH_HANG: 'formThemMoiVatTuXuatKhoKH',
    KHAC: 'formThemMoiVatTuXuatKhoKhac',
    HUY: "formThemMoiVatTuXuatHuy",
    HAO_PHI: "themVatTuXuatHaoPhi",
  }
}

export const EXPORT_OPTIONS: any[] = [
  // { code: "", name: "Xuất kho vật tư", modelId: "phieuXuatKhoAdd", modelIdVT: "formThemMoiVatTuXuatKho" },
  {
    name: "Xuất nhà cung cấp",
    code: EXPORT_CODE.NCC,
    tracker: WF_TRACKER.XUAT_KHO,
    type: TYPE_WH.EXPORT,
    modelId: EXPORT_FORM_CODE.GENERAL.NCC,
    modelIdVT: EXPORT_FORM_CODE.VAT_TU.NCC,
  },
  {
    name: "Xuất khách hàng",
    code: EXPORT_CODE.KHACH_HANG,
    tracker: WF_TRACKER.THU_NGAN,
    type: TYPE_WH.THU_NGAN,
    modelId: EXPORT_FORM_CODE.GENERAL.KHACH_HANG,
    modelIdVT: EXPORT_FORM_CODE.VAT_TU.KHACH_HANG,
  },
  {
    name: "Xuất khác",
    code: EXPORT_CODE.KHAC,
    tracker: WF_TRACKER.XUAT_KHO,
    type: TYPE_WH.EXPORT,
    modelId: EXPORT_FORM_CODE.GENERAL.KHAC,
    modelIdVT: EXPORT_FORM_CODE.VAT_TU.KHAC,
  },
  {
    name: "Xuất huỷ",
    code: EXPORT_CODE.HUY,
    tracker: WF_TRACKER.XUAT_KHO,
    type: TYPE_WH.EXPORT,
    modelId: EXPORT_FORM_CODE.GENERAL.HUY,
    modelIdVT: EXPORT_FORM_CODE.VAT_TU.HUY,
  }
];
export const EXPORT_OPTIONS_KHAC: any[] = [
  // { code: "", name: "Xuất kho vật tư", modelId: "phieuXuatKhoAdd", modelIdVT: "formThemMoiVatTuXuatKho" },
  {
    name: "Xuất nhà cung cấp",
    code: EXPORT_CODE.NCC,
    tracker: WF_TRACKER.XUAT_KHO,
    type: TYPE_WH.EXPORT,
    modelId: EXPORT_FORM_CODE.GENERAL.NCC,
    modelIdVT: EXPORT_FORM_CODE.VAT_TU.NCC,
  },
  // {
  //   name: "Xuất khách hàng",
  //   code: EXPORT_CODE.KHACH_HANG,
  //   tracker: WF_TRACKER.THU_NGAN,
  //   type: TYPE_WH.THU_NGAN,
  //   modelId: EXPORT_FORM_CODE.GENERAL.KHACH_HANG,
  //   modelIdVT: EXPORT_FORM_CODE.VAT_TU.KHACH_HANG,
  // },
  {
    name: "Xuất khác",
    code: EXPORT_CODE.KHAC,
    tracker: WF_TRACKER.XUAT_KHO,
    type: TYPE_WH.EXPORT,
    modelId: EXPORT_FORM_CODE.GENERAL.KHAC,
    modelIdVT: EXPORT_FORM_CODE.VAT_TU.KHAC,
  },
  {
    name: "Xuất huỷ",
    code: EXPORT_CODE.HUY,
    tracker: WF_TRACKER.XUAT_KHO,
    type: TYPE_WH.EXPORT,
    modelId: EXPORT_FORM_CODE.GENERAL.HUY,
    modelIdVT: EXPORT_FORM_CODE.VAT_TU.HUY,
  },
];

export const EXPORT_OPTIONS_HAO_PHI = [
  {
    name: "Xuất hao phí khoa/phòng",
    code: EXPORT_CODE.HAO_PHI,
    tracker: WF_TRACKER.XUAT_KHO,
    type: TYPE_WH.EXPORT,
    modelId: EXPORT_FORM_CODE.GENERAL.HAO_PHI,
    modelIdVT: EXPORT_FORM_CODE.VAT_TU.HAO_PHI,
  },
];