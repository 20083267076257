import { FC, useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useFormikContext } from "formik";
import "../../../PhanHeThongKe.scss";
import { VatTu } from "../models/ThemMoiVatTuModel";
import FormThongTinChung from "./FormThongTinChung";
import { getListFormTab } from "../../../services/ThongKeServices";
import { CODE } from "../../../../utils/Constant";
import { AppContext } from "../../../../appContext/AppContext";
import TabThongTinVatTu from "../../FormTabGererate/tab-auto-generate/TabThongTinAutoGenerateNoValidate";
import { PhanHeThongKeContextProps, PhanHeThongKeContext } from "../../../PhanHeThongKe"
import "../ThemMoiVatTu.scss"

type Props = {
  isReload?: boolean;
  onHide: () => void;
  listJsonGenerate?: any;
};

const FormThemMoi: FC<Props> = (props) => {
  const { isReload, listJsonGenerate, onHide } = props;
  const {
    handleSubmit,
    setValues,
    setTouched,
    setFieldValue,
    values,
    errors,
    touched,
    isValid,
    handleChange
  } = useFormikContext<VatTu>();
  const { currentTab, setIsLoading } = useContext(AppContext);
  const [listTab, setListTab] = useState([])
  const { nhomVatTu } = useContext<PhanHeThongKeContextProps>(PhanHeThongKeContext);

  useEffect(() => {
    getListform();
  }, [nhomVatTu?.id]);

  const getListform = async () => {
    try {
      setIsLoading(true);
      let { data } = await getListFormTab("ADD", nhomVatTu);
      if (CODE.SUCCESS === data?.code) {
        setIsLoading(false);
        let ListData = data?.data?.map((item: any) => {
          return {
            ...item,
            eventKey: item?.eventKey,
            component: <TabThongTinVatTu
              ThongTinTab={item || {}}
            />
          }
        })
        setListTab(ListData)
      } else {
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-gray">
      <Form className="receive" onSubmit={handleSubmit}>
        <div className=" spaces bg-white width-100 h-form-add-ttt" >
          <h4 className="text-title fw-bold fs-5 ml-10 spaces mb-5">
            Thông tin chung
          </h4>
          <FormThongTinChung modelID="thongTinChung" listJsonGenerate={listJsonGenerate} />
        </div>
        {/* <div className="d-flex mt-5 flex-column spaces bg-white width-100">
          <FormThongTinVatTu className={"form-them-moi-vat-tu"} isChildrenTab={true} listDsTab={listTab} idTab={"id2"} />
        </div> */}
        <div className="spaces footer-buttom w-100 d-flex justify-content-end bg-white mt-2 gap-6 py-8 z-index-2">
          <Button className="btn-fill mx-3 spaces min-w-90px btn btn-primary" type="submit">
            <i className="bi bi-floppy"></i>
            Lưu
          </Button>
          <Button variant="btn-fill spaces mx-3 mr-10  min-w-90px btn btn-secondary" onClick={onHide}>
            <i className="bi bi-x-lg"></i>
            Huỷ
          </Button>
        </div>
      </Form >
    </div >
  );
};
export default FormThemMoi;
