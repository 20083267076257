import { columnNamesType, OctTable } from "@oceantech/oceantech-ui";
import { useEffect, useState, useContext } from "react";
import { PhanHeXuatKhoContext } from "../../../PhanHeXuatKho";
import CustomIconButton from "../../../../component/custom-icon-button/CustomIconButton";
import { formatDateToDDMMYYYY, formatMoney } from "../../../../utils/FormatUtils";
import { AppContext } from "../../../../appContext/AppContext";
import { deleteVatTuXuatKho, getDsThuocXuatKho } from "../../../services/servicesXuatKho";
import { CODE, DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE, TYPE_WH } from "../../../../utils/Constant";
import { toast } from "react-toastify";
import { WMS_STATUS } from "../../../constants/constantsXuatKho";

type Props = {
  id: string;
  statusPhieu?: any;
  warehouseExportType?: any
};

const ThongTinXuatThuoc = ({ id, statusPhieu, warehouseExportType }: Props) => {
  const { setIsLoading } = useContext(AppContext);
  const { thongTinPhieu, setThongTinPhieu, setVatTuSelected } = useContext(PhanHeXuatKhoContext);

  const getVatTuXuatKho = async () => {
    setIsLoading(true);
    try {
      const { data } = await getDsThuocXuatKho({ type: TYPE_WH.EXPORT, slipId: id });
      if (CODE.SUCCESS === data.code) {
        setThongTinPhieu({
          ...thongTinPhieu,
          dsThuoc: data.data.content,
        })
      }
    } catch (error) {
      toast.error('Lỗi tải danh sách vật tư, vui lòng thử lại')
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    id && getVatTuXuatKho();
  }, [id]);

  const handleDeleteRow = (index: number) => {
    const newDsThuoc = [...(thongTinPhieu.dsThuoc || [])];
    if (index >= 0 && index < newDsThuoc.length) {
      newDsThuoc.splice(index, 1);
    };

    setThongTinPhieu({ ...thongTinPhieu, dsThuoc: newDsThuoc });
  };

  const handleEditRow = (row: any, index?: any) => {
    debugger
    setVatTuSelected({
      ...row,
      ...row?.item,
      id: row?.id || null,
      consignment: row?.consignment || row?.item?.consignment,
      sellingPrice: row?.sellingPrice || row?.item?.sellingPrice,
      hospitalPrice: row?.hospitalPrice || row?.item?.hospitalPrice,
      serialNumber: row?.serialNumber || row?.item?.serialNumber,
      expiryDate: row?.expiryDate || row?.item?.expiryDate,
      code: row.code || row.item?.code,
      countryofOriginName: row?.countryofOriginName || row?.item?.countryofOriginName,
      manufacturerName: row?.manufacturerName || row?.item?.manufacturerName,
      index: index
    });
  };

  let columns: columnNamesType[] = [
    {
      name: "STT",
      field: "stt",
      headerStyle: {
        width: "50px"
      },
      cellStyle: {
        textAlign: "center"
      },
      render: (row, index, stt) => {
        return stt
      },
    },
    {
      name: 'TT',
      field: 'custom',
      headerStyle: {
        width: 50,
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (row, index, stt) => {
        return (
          <div className="d-flex gap-5">
            {WMS_STATUS.warehouse_approved !== statusPhieu?.status &&
              <>
                <CustomIconButton
                  variant="delete"
                  onClick={() => handleDeleteRow(index)}
                >
                  <i className="bi bi-trash3-fill text-danger"></i>
                </CustomIconButton>
                <CustomIconButton
                  variant="edit"
                  onClick={() => handleEditRow(row, index)}
                >
                  <i className="bi bi-pencil-square text-navy"></i>
                </CustomIconButton>
              </>
            }
          </div>
        )
      }
    },
    {
      name: "Mã thuốc",
      field: "code",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row, index, stt) => row?.item?.code
    },
    {
      name: "Tên thuốc",
      field: "item",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row, index, stt) => row.item?.name,
    },
    {
      name: "Số lượng",
      field: "totalQuantity",
      headerStyle: {
        minWidth: "80px",
      },
      cellStyle: {
        textAlign: 'end',
      },
      render: (row, index, stt) => formatMoney(row?.totalQuantity) || "",
    },
    {
      name: "Đơn vị",
      field: "unitofMeasureName",
      headerStyle: {
        minWidth: "105px"
      },
      render: (row, index, stt) => row?.item?.unitofMeasureName,
    },
    {
      name: "SL khả dụng",
      field: "availableQuantity",
      headerStyle: {
        minWidth: "100px"
      },
      cellStyle: {
        textAlign: 'end',
      },
      render: (row, index, stt) => formatMoney(row?.item?.availableQuantity),
    },
    {
      name: "Giá bán",
      field: "hospitalPrice",
      headerStyle: {
        minWidth: "100px"
      },
      cellStyle: {
        textAlign: 'end',
      },
      render: (row, index, stt) => formatMoney(row?.hospitalPrice || row?.item?.hospitalPrice),
    },
    {
      name: "% VAT",
      field: "vat",
      headerStyle: {
        minWidth: "60px"
      },
      cellStyle: {
        textAlign: 'end',
      },
      render: (row, index, stt) => row?.vat || row?.item?.vat || 0,
    },
    {
      name: "Thành tiền",
      field: "totalAmount",
      headerStyle: {
        minWidth: "100px"
      },
      cellStyle: {
        textAlign: 'end',
      },
      render: (row, index, stt) => {
        const quantity = parseFloat(row?.totalQuantity || row?.item?.totalQuantity || 0);
        const price = parseFloat(row?.hospitalPrice || row?.item?.hospitalPrice || 0);
        const vat = parseFloat(row?.vat || row?.item?.vat || 0);
        const result = (quantity * price * (1 + vat / 100));
        return formatMoney(result);
      },
    },
    {
      name: "Số lô",
      field: "consignment",
      headerStyle: {
        minWidth: "75px"
      },
      render: (row, index, stt) => row?.consignment || row?.item?.consignment,
    },
    {
      name: "Hạn sử dụng",
      field: "expiryDate",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row, index, stt) => formatDateToDDMMYYYY(row?.expiryDate || row?.item?.expiryDate),
    },
  ]
  let columnsNcc: columnNamesType[] = [
    {
      name: "STT",
      field: "stt",
      headerStyle: {
        width: "50px"
      },
      cellStyle: {
        textAlign: "center"
      },
      render: (row, index, stt) => {
        return stt
      },
    },
    {
      name: 'TT',
      field: 'custom',
      headerStyle: {
        width: 50,
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (row, index, stt) => {
        return (
          <div className="d-flex gap-5">
            {WMS_STATUS.warehouse_approved !== statusPhieu?.status &&
              <>
                <CustomIconButton
                  variant="delete"
                  onClick={() => handleDeleteRow(index)}
                >
                  <i className="bi bi-trash3-fill text-danger"></i>
                </CustomIconButton>
                <CustomIconButton
                  variant="edit"
                  onClick={() => handleEditRow(row, index)}
                >
                  <i className="bi bi-pencil-square text-navy"></i>
                </CustomIconButton>
              </>
            }
          </div>
        )
      }
    },
    {
      name: "Mã thuốc",
      field: "code",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row, index, stt) => row?.item?.code
    },
    {
      name: "Tên thuốc",
      field: "item",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row, index, stt) => row.item?.name,
    },
    {
      name: "Số lượng",
      field: "totalQuantity",
      headerStyle: {
        minWidth: "80px",
      },
      cellStyle: {
        textAlign: 'end',
      },
      render: (row, index, stt) => formatMoney(row?.totalQuantity) || "",
    },
    {
      name: "Đơn vị",
      field: "unitofMeasureName",
      headerStyle: {
        minWidth: "105px"
      },
      render: (row, index, stt) => row?.item?.unitofMeasureName,
    },
    {
      name: "SL khả dụng",
      field: "availableQuantity",
      headerStyle: {
        minWidth: "100px"
      },
      cellStyle: {
        textAlign: 'end',
      },
      render: (row, index, stt) => formatMoney(row?.item?.availableQuantity),
    },
    {
      name: "Giá nhập",
      field: "sellingPrice",
      headerStyle: {
        minWidth: "100px"
      },
      cellStyle: {
        textAlign: 'end',
      },
      render: (row, index, stt) => formatMoney(row?.sellingPrice || row?.item?.sellingPrice),
    },
    {
      name: "% VAT",
      field: "vat",
      headerStyle: {
        minWidth: "60px"
      },
      cellStyle: {
        textAlign: 'end',
      },
      render: (row, index, stt) => row?.vat || row?.item?.vat || 0,
    },
    {
      name: "Thành tiền",
      field: "totalAmount",
      headerStyle: {
        minWidth: "100px"
      },
      cellStyle: {
        textAlign: 'end',
      },
      render: (row, index, stt) => {
        const quantity = parseFloat(row?.totalQuantity || row?.item?.totalQuantity || 0);
        const price = parseFloat(row?.sellingPrice || row?.item?.sellingPrice || 0);
        const vat = parseFloat(row?.vat || row?.item?.vat || 0);
        const result = (quantity * price * (1 + vat / 100));
        return formatMoney(result);
      },
    },
    {
      name: "Số lô",
      field: "consignment",
      headerStyle: {
        minWidth: "75px"
      },
      render: (row, index, stt) => row?.consignment || row?.item?.consignment,
    },
    {
      name: "Hạn sử dụng",
      field: "expiryDate",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row, index, stt) => formatDateToDDMMYYYY(row?.expiryDate || row?.item?.expiryDate),
    },
  ]

  return (
    <div className="bg-white spaces mb-6 pb-15 fl-1">
      <OctTable
        id="h-bang-ds-xuat-thuoc"
        className="spaces table-bang-ds-xuat-thuoc"
        columns={warehouseExportType?.code == "X002" ? columns : columnsNcc}
        data={thongTinPhieu?.dsThuoc || []}
        justFilter={false}
        clearToolbar
        noToolbar
        noPagination
      />
    </div>
  );
};

export default ThongTinXuatThuoc;
