export const statusOptions = {
  chuaDongDu: { value: 1, name: "Chưa đóng đủ" },
  daDongDu: { value: 2, name: "Đã đóng đủ" },
};


export const CODE_LOAI_PHIEU = {
  THU_TIEN: {
    code: "TT",
    name: "Thu tiền",
  },
  HOAN_TIEN: {
    code: "HT",
    name: "Hoàn tiền",
  },
  TAM_UNG: {
    code: "TU",
    name: "Tạm ứng",
  },
  HOAN_UNG: {
    code: "HU",
    name: "Hoàn ứng",
  },
  THU_TIEN_0_DONG: {
    code: "TT0D",
    name: "Thu tiền 0 đồng",
  },
};

export const LOAI_PHIEU = [
  {
    code: "TT",
    name: "Thu tiền",
  },
  {
    code: "HT",
    name: "Hoàn tiền",
  },
  {
    code: "TU",
    name: "Tạm ứng",
  },
  {
    code: "HU",
    name: "Hoàn ứng",
  },
  {
    code: "TT0D",
    name: "Thu tiền 0 đồng",
  },
];

export const LIST_HINH_THUC = [
  {
    code: "TM",
    name: "Tiền mặt",
  },
  {
    code: "CK",
    name: "Chuyển khoản",
  },
];

export const PATIENT_TYPE = {
  BHYT: {
    code: "BHYT",
    name: "BHYT",
  },
  VIEN_PHI: {
    code: "VP",
    name: "Viện phí",
  },
  YEU_CAU: {
    code: "YC",
    name: "Yêu cầu",
  },
  KHAM_DOAN: {
    code: "PE",
    name: "Khám đoàn",
  },
  MIEN_PHI: {
    code: "MP",
    name: "Miễn phí",
  },
};

export const TYPE_CHECKS = [
  { label: "BHYT", type: PATIENT_TYPE.BHYT.code },
  { label: "Viện phí", type: PATIENT_TYPE.VIEN_PHI.code },
  { label: "Miễn phí", type: PATIENT_TYPE.MIEN_PHI.code },
  { label: "Yêu cầu", type: PATIENT_TYPE.YEU_CAU.code },
];

export const INV_TYPE_CODE = {
  PHIEU_THU: {
    code: "PHIEU_THU",
    name: "Phiếu thu",
  },
  PHIEU_TAM_UNG: {
    code: "TAM_UNG",
    name: "Phiếu tạm ứng",
  },
  PHIEU_HOAN: {
    code: "PHIEU_HOAN",
    name: "Phiếu hoàn",
  },
};

export const HINH_THUC_TYPE = {
  CK: {
    code: "BANKTRANSFER",
    name: "Chuyển khoản",
  },
  TM: {
    code: "CASH",
    name: "Tiền mặt",
  },
};

export const CATEGORY_TYPE = {
  HINH_THUC: {
    code: 21,
    name: "Hình thức",
  },
  LOAI_PHIEU: {
    code: 22,
    name: "Loại phiếu",
  },
};

export const HUY = {
  THUC_HIEN: "thực hiện",
  TRA_KET_QUA: "trả kết quả",
};

export const LIST_FORMAL = [
  {
    id: 1,
    value: "1",
    name: "Ngày vào viện",
    code: "visitDateStarted",
  },
  {
    id: 2,
    value: "2",
    name: "Ngày ra viện",
    code: "visitDateStopped",
  },
  {
    id: 3,
    value: "3",
    name: "Ngày thanh toán",
    code: "paymentApprovalTime",
  },
];

export const LIST_FORMAL_OBJECT = {
  NGAY_VAO_VIEN: {
    id: 1,
    value: "1",
    name: "Ngày vào viện",
    code: "visitDateStarted",
  },
  NGAY_RA_VIEN: {
    id: 2,
    value: "2",
    name: "Ngày ra viện",
    code: "visitDateStopped",
  },
  NGAY_THANH_TOAN: {
    id: 3,
    value: "3",
    name: "Ngày thanh toán",
    code: "paymentApprovalTime",
  },
};

export const TYPE_VT = {
  THUOC: "6",
  VAT_TU: "7",
  LUU_PHIEU_TT: 6,
};

export const WF_STATUS_ID = {
  NEW: 1,
  WAIT_APPROVE: 2,
  APPROVED: 3,
  REFUSE: 4,
  PAID: 5,
  EDIT: 6,
  CANCEL: 7,
  WAIT_CREAT: 13,
}; 
  
