import { useFormikContext } from "formik";
import { Button, Form } from "react-bootstrap";
import GenerateFormComponent from "../../component/generate-form/GenerateFormHTMLNoValid";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { generateForm, getSurplusPercent, roundToDecimalPlaces } from "../../utils/AppFunction";
import { AppContext } from "../../appContext/AppContext";
import DynamicComponent from "../../component/GenerateDynamicComponent";
import { TypeNhapKho } from "../models/NhapKhoModel";
import { CODE_NHAP_KHO, INIT_GENERAL_VALUES } from "../constants/constansNhapKho";
import { getListVatTuByCode } from "../services/services";
import { CODE } from "../../utils/Constant";
import { getDsThuocXuatKho } from "../../phan-he-xuat-kho/services/servicesXuatKho";
import { toast } from "react-toastify";
import { localStorageItem } from "../../utils/LocalStorage";
import { generateRandomId } from "../../utils/FormatUtils";

type Props = {
  listJsonGenerateVT?:any;
  generalValues: any;
  setGeneralFieldValue: any;
  indexRowEdit: number | null;
  isView: boolean;
  warehouseId: number;
  type: TypeNhapKho;
  dsNhapKho: any[];
  setDsNhapKho: Dispatch<SetStateAction<any>>;
  setInitialValuesFormThemVT: Dispatch<SetStateAction<any>>;
};

const FormThemVatTu = ({ generalValues,listJsonGenerateVT, indexRowEdit, isView, warehouseId, type, dsNhapKho, setDsNhapKho, setGeneralFieldValue, setInitialValuesFormThemVT }: Props) => {
  const { setIsLoading } = useContext(AppContext);
  const {
    handleSubmit,
    handleChange,
    setValues,
    setFieldValue,
    values,
    touched,
    errors,
  } = useFormikContext<any>();

  const dataUser = localStorageItem.get("current-user") ? localStorageItem.get("current-user") : {};

  const handleTotalAmount = () => {
    const formData = values.themVatTuPhieuNhapKho || {};
    const price = parseFloat(type.code === CODE_NHAP_KHO.TRA_LE ? (formData.hospitalPrice || 0) : (formData.sellingPrice || 0));
    const quantity = parseFloat(formData.totalQuantity || 0);
    const vat = parseFloat(formData.vat || 0);
    const priceVat = price * (1 + vat / 100);
    const discountRate = formData.discountRate || 0;
    const realPrice = price * (1 - discountRate / 100);
    const result = realPrice * quantity * (1 + vat / 100);
    const surpluspercent = getSurplusPercent(priceVat);

    const newFormData = {
      ...formData,
      realPrice: roundToDecimalPlaces(realPrice),
      totalAmount: roundToDecimalPlaces(result),
      surplus: priceVat ? surpluspercent : '',
    };
    setFieldValue("themVatTuPhieuNhapKho", newFormData);
  };

  useEffect(() => {
    handleTotalAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.themVatTuPhieuNhapKho?.totalQuantity,
    values.themVatTuPhieuNhapKho?.hospitalPrice,
    values.themVatTuPhieuNhapKho?.sellingPrice,
    values.themVatTuPhieuNhapKho?.discountRate,
    values.themVatTuPhieuNhapKho?.vat,
  ]);

  const handlePrice = () => {
    const formData = values.themVatTuPhieuNhapKho || {};
    if (formData.isFirstClick || formData.isFirstChange) {
      setFieldValue("themVatTuPhieuNhapKho", { ...formData, isFirstClick: false, isFirstChange: false });
      return;
    };
    const price = parseFloat((formData.sellingPrice || 0));
    const vat = parseFloat(formData.vat || 0);
    const priceVat = price * (1 + vat / 100);
    const surpluspercent = getSurplusPercent(priceVat);
    const surplus = priceVat * (surpluspercent / 100);
    const salePrice = surplus + priceVat;

    const newFormData = {
      ...formData,
      surplus: priceVat ? surpluspercent : '',
      salePrice: salePrice ? roundToDecimalPlaces(salePrice) : '',
      hospitalPrice: salePrice ? roundToDecimalPlaces(salePrice) : '',
      servicePrice: salePrice ? roundToDecimalPlaces(salePrice) : '',
    };
    setFieldValue("themVatTuPhieuNhapKho", newFormData);
  };

  useEffect(() => {
    handlePrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.themVatTuPhieuNhapKho?.sellingPrice,
    values.themVatTuPhieuNhapKho?.vat,
  ]);

  const getListVatTuKH = async () => {
    try {
      setIsLoading(true);
      const { data } = await getListVatTuByCode(generalValues.nhapKho.codeExport?.trim());
      if (CODE.SUCCESS === data?.code) {
        setGeneralFieldValue("nhapKho", {
          ...data.data,
          doCreation: new Date(),
          creator: dataUser.sub,
          code: generalValues.nhapKho?.code || null,
          id: generalValues.nhapKho?.id || null,
          mateWh: 'DR01',
          mateWhId: data.data.warehouseId,
          patientGender: data.data.patientGender || data.data.patientGenderName === 'Nam' ? 'G001' : 'G002',
          codeExport: generalValues.nhapKho.codeExport,
          codeExportIsValid: true,
        });

        const listVatTuResponse = await getDsThuocXuatKho({ slipId: data.data.id });
        if (CODE.SUCCESS === listVatTuResponse?.data?.code) {
          const lstVatTu = listVatTuResponse.data.data.content.map((item: any) => ({ ...item, id: null, codeExportIsValid: true, fakeId: generateRandomId() }))
          setDsNhapKho(lstVatTu);
        } else {
          toast.error('Lỗi tải danh sách thuốc/VT, vui lòng thử lại');
        }
      } else {

        const newLstVatTu = [...dsNhapKho].map(item => ({ ...item, codeExportIsValid: false, fakeId: generateRandomId() }));
        setDsNhapKho(newLstVatTu);
        setGeneralFieldValue("nhapKho", { ...generalValues.nhapKho, codeExportIsValid: false });
        toast.error(data.message)
      }
    } catch (error) {
      toast.error('Có lỗi xảy ra, vui lòng thử lại');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setFieldValue("themVatTuPhieuNhapKho", {});
    if (generalValues.nhapKho.codeExport?.trim() && !isView) {
      const delayDebounceFn = setTimeout(() => {
        getListVatTuKH();
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    } else {
      const newLstVatTu = [...dsNhapKho].map((item: any) => ({ ...item, codeExportIsValid: false }));
      setDsNhapKho(newLstVatTu);
      setGeneralFieldValue("nhapKho", {
        ...generalValues.nhapKho,
        codeExportIsValid: generalValues.nhapKho.id ? generalValues.nhapKho.codeExportIsValid : false
      });
    }
  }, [generalValues.nhapKho.codeExport]);


  const handler = (values: any) => {
    setInitialValuesFormThemVT({ ...values, isFirstChange: true });
  };

  return (
    <Form onSubmit={handleSubmit} >
      <GenerateFormComponent
        isView={
          isView
          || !generalValues.nhapKho?.mateWh
          || (indexRowEdit === null && generalValues.nhapKho.codeExport)
        }
        isViewSelectThuoc={generalValues.nhapKho.codeExport}
        listFieldAuto={listJsonGenerateVT}
        handleChange={handleChange}
        setValues={setValues}
        values={values}
        touched={touched}
        errors={errors}
        propsRenderButton={handler}
        lstSelectedRenderButton={dsNhapKho}
        setFieldValue={setFieldValue}
        modelID={"themVatTuPhieuNhapKho"}
        DynamicClass={"pl-38"}
        customComponent={DynamicComponent}
        warehouseId={warehouseId}
        isNhapKho={[CODE_NHAP_KHO.NHA_CUNG_CAP, CODE_NHAP_KHO.KHAC].includes(type.code)}
      />
      <div className="spaces mt-10">
        {(
          !isView
          && (!!generalValues.nhapKho?.receWarehouse || !!generalValues.nhapKho?.mateWh)
          && (indexRowEdit !== null || !generalValues.nhapKho.codeExport)
        ) &&
          <Button className="btn btn-fill spaces min-w-90" onClick={e => handleSubmit(values)}>
            {indexRowEdit === null ? "Thêm thuốc/VT" : "Cập nhật thuốc/VT"}
          </Button>
        }
      </div>
    </Form>
  );
};

export default FormThemVatTu;
