import { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { AppContext } from "../../appContext/AppContext";
import CustomIconButton from "../../component/custom-icon-button/CustomIconButton";
import InputSearch from "../../component/InputSearch";
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import { TablePagination } from "../../component/table/components/TablePagination";
import { TableCustom } from "../../component/table/table-custom/TableCustom";
import TextField from "../../component/TextField";
import { CODE, DEFAULT_PAGE_INDEX, KEY, PHIEU_IN_ENDPOINT, SELECTION_MODE, TYPE_WH, WAREHOUSE_ID, WF_TRACKER } from "../../utils/Constant";
import { handlePagesChange, handleRowsPerPageChange, rowsForPage } from "../../utils/PageUtils";
import { DanhSachPhieuColumns } from "../constants/ColumnsDieuChuyen";
import WfStatusSummary from "../../workflows/components/WfStatusSummary";
import { CODE_DIEU_CHUYEN, FILTER_WAREHOUSE, INIT_VALUES_NK, LST_WAREHOUSE, STATUS_DIEU_CHUYEN, TRANSFER_OPTIONS, TRANSFER_WAREHOUSE } from "../constants/constansDieuChuyen";
import { deletePhieuDieuChuyen, searchPhieuDieuChuyen } from "../services/services";
import { ConfirmDialog } from "../../component/ConfirmDialog";
import { toast } from "react-toastify";
import { PhanHeDieuChuyenContext } from "../PhanHeDieuChuyen";
import DialogPhieuDieuChuyen from "./DialogPhieuDieuChuyen";
import "../PhanHeDieuChuyen.scss";
import { getSummaryStatusByType } from "../../workflows/services/WfWorkflowsService";
import ButtonIn from "../../component/button-in-phieu";
import AutocompleteObjectV2 from "../../component/AutocompleteObjectV2";
import { formatDateFromDTOCustom, formatDateToDTOCustom } from "../../utils/AppFunction";

type Props = {
  warehouseId: number;
}

export const FormDieuChuyen: FC<Props> = (props) => {
  const { warehouseId } = props;
  const { setIsLoading } = useContext(AppContext);
  const { setDsDieuChuyen, setOnlyView } = useContext(PhanHeDieuChuyenContext);
  const [dsPhieuDieuChuyen, setDsPhieuDieuChuyen] = useState<any[]>([]);
  const [openDialogThemMoi, setOpenDialogThemMoi] = useState<boolean>(false);
  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState<boolean>(false);
  const [summaryStatus, setSummaryStatus] = useState<any[]>([]);
  const [warehouseTransferType, setWarehouseTransferType] = useState<any>();
  const [itemSelected, setItemSelected] = useState<any>({
    ...INIT_VALUES_NK,
    ...LST_WAREHOUSE[warehouseId as keyof typeof LST_WAREHOUSE],
    ...(warehouseId === WAREHOUSE_ID.NHA_THUOC ? {...TRANSFER_WAREHOUSE.NOI_VIEN} : warehouseId === WAREHOUSE_ID.NOI_VIEN ? {...TRANSFER_WAREHOUSE.NHA_THUOC} : {})
  });
  const [searchData, setSearchData] = useState<any>();
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
  const [configTable, setConfigTable] = useState<any>({
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  });
  const handleChangeSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchData({
      ...searchData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const res = await deletePhieuDieuChuyen(itemSelected.id);
      if (CODE.SUCCESS === res.status) {
        toast.success("Xóa phiếu thành công");
        handleCloseDialog();
      }
    } catch (error: any) {
      const message = error?.message || "Có lỗi xảy ra, vui lòng thử lại";
      toast.error(message);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseDialog = () => {
    updatePageData();
    setDsDieuChuyen([]);
    setOnlyView(false);
    setOpenDialogConfirmDelete(false);
    setOpenDialogThemMoi(false);
    setItemSelected({
      ...INIT_VALUES_NK,
      ...LST_WAREHOUSE[warehouseId as keyof typeof LST_WAREHOUSE],
      ...(warehouseId === WAREHOUSE_ID.NHA_THUOC ? {...TRANSFER_WAREHOUSE.NOI_VIEN} : warehouseId === WAREHOUSE_ID.NOI_VIEN ? {...TRANSFER_WAREHOUSE.NHA_THUOC} : {}),
      randomValue: Math.random()
    });
  };

  const updatePageData = async () => {
    try {
      setIsLoading(true);
      const summaryResponse = await getSummaryStatusByType(
        warehouseTransferType.tracker,
        TYPE_WH.TRANSFER,
        warehouseTransferType,
        warehouseId,
      );
      setSummaryStatus(summaryResponse?.data?.data || []);
      const { data } = await searchPhieuDieuChuyen({
        ...searchData,
        type: TYPE_WH.TRANSFER,
        doCreationFromDate: formatDateFromDTOCustom(searchData?.doCreationFromDate),
        doCreationToDate: formatDateToDTOCustom(searchData?.doCreationToDate),
        warehouseExportType: warehouseTransferType?.code,
        warehouseId: warehouseId,
        pageSize: rowsPerPage,
        pageIndex: page,
      });
      if (CODE.SUCCESS === data.code) {
        setDsPhieuDieuChuyen(data?.data?.content || []);
        setConfigTable({
          totalPages: (data.data.pageable.totalElements / data.data.pageable.pageSize).toFixed(),
          totalElements: data?.data?.pageable?.totalElements,
          // numberOfElements: data?.data?.pageable?.pageNumber,
        })
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    updatePageData();
  }, [rowsPerPage, page, warehouseTransferType?.code]);

  useEffect(() => {
    setWarehouseTransferType(TRANSFER_OPTIONS[0]);
  }, [warehouseId]);

  const columnsActionsNhapDieuChuyen = {
    Header: (props: any) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Thao tác"}
        className="text-center text-light min-w-80px"
      />
    ),
    id: "actions",
    Cell: ({ ...props }) => {
      const rowData = props.cell.row.original;
      return (
        <div className="d-flex justify-content-center gap-3">
          <>
            <CustomIconButton
              variant="edit"
              onClick={() => {
                setItemSelected(rowData);
                setOnlyView(true);
                setOpenDialogThemMoi(true);
              }}
            >
              <i className="bi bi-pencil-square text-navy"></i>
            </CustomIconButton>
            {rowData?.statusCode === STATUS_DIEU_CHUYEN.CODE_NEW &&
              <CustomIconButton
                variant="delete"
                onClick={() => {
                  setItemSelected(rowData);
                  setOpenDialogConfirmDelete(true);
                }}
              >
                <i className="bi bi-trash3-fill text-danger"></i>
              </CustomIconButton>
            }
            <ButtonIn
              isIcon
              params={{
                slipId: rowData?.id,
              }}
              endpoint={rowData?.reqWhId === warehouseId ? PHIEU_IN_ENDPOINT.NHAP_DIEU_CHUYEN.NHAP_KHO : PHIEU_IN_ENDPOINT.NHAP_DIEU_CHUYEN.XUAT_KHO}
            />
          </>
        </div>
      )
    },
  };

  const handleChangeTypeWarehouseTransferType = (type?: any) => {
    setWarehouseTransferType(type)
    setSearchData({})
  }

  const columnsActionsXuatDieuChuyen = {
    Header: (props: any) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Thao tác"}
        className="text-center text-light min-w-80px"
      />
    ),
    id: "actions",
    Cell: ({ ...props }) => {
      const rowData = props.cell.row.original;
      return (
        <div className="d-flex justify-content-center gap-3">
          <>
            <CustomIconButton
              variant="edit"
              onClick={() => {
                setItemSelected(rowData);
                setOnlyView(true);
                setOpenDialogThemMoi(true);
              }}
            >
              <i className="bi bi-pencil-square text-navy"></i>
            </CustomIconButton>
            {rowData?.statusCode === STATUS_DIEU_CHUYEN.CODE_NEW &&
              <CustomIconButton
                variant="delete"
                onClick={() => {
                  setItemSelected(rowData);
                  setOpenDialogConfirmDelete(true);
                }}
              >
                <i className="bi bi-trash3-fill text-danger"></i>
              </CustomIconButton>
            }
            <ButtonIn
              isIcon
              params={{
                slipId: rowData?.id,
              }}
              endpoint={rowData?.reqWhId === warehouseId ? PHIEU_IN_ENDPOINT.XUAT_DIEU_CHUYEN.XUAT_KHO : PHIEU_IN_ENDPOINT.XUAT_DIEU_CHUYEN.NHAP_KHO}
            />
          </>
        </div>
      )
    },
  };

  return (
    <div className="bg-white h-100">
      <div className="flex-1 flex flex-column">
        <div className="d-flex bg-gray ">
          <div className="spaces width-100 bg-gray radius-2">
            <div className="flex bg-white spaces width-100" >
              <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 px-10">
                Danh sách phiếu điều chuyển
              </h4>
              <div className="flex bg-white spaces width-20 mt-2 ml-12" >
                <AutocompleteObjectV2
                  options={TRANSFER_OPTIONS}
                  isSearchDefauilt={true}
                  name={"WmsCode"}
                  onChange={(options: any) => handleChangeTypeWarehouseTransferType(options)}
                  value={warehouseTransferType || ""}
                  className="autocomplete-custom-renderform  radius spaces width-100 h-29"
                  isClearable={false}
                />
              </div>
            </div>
            <div className="d-flex  mt-1 flex-column spaces width-100">
              {/* <div className="bg-white"> */}
                <div className="d-flex spaces pr-8 mt-6 bg-white justify-content-between align-items-center mb-6">
                  <div className="bg-white flex align-items-center ">
                    <Button
                      className="btn-fill ml-10 mr-10 spaces min-w-90px btn btn-primary"
                      onClick={() => setOpenDialogThemMoi(true)}
                    >
                      <i className="bi bi-plus-lg text-pri fs-3"></i>
                      Thêm
                    </Button>
                  </div>
                  <div className="d-flex spaces mx-10 ">
                    <TextField
                      label="Từ ngày"
                      name="doCreationFromDate"
                      type="date"
                      value={searchData?.doCreationFromDate || ""}
                      className="flex"
                      labelClassName="spaces mt-5 mr-5"
                      onChange={handleChangeSearchData}
                    />
                  </div>
                  <div className="d-flex spaces mr-10">
                    <TextField
                      label="Đến ngày"
                      name="doCreationToDate"
                      type="date"
                      className="flex"
                      value={searchData?.doCreationToDate || ""}
                      labelClassName="spaces mt-5 mr-5"
                      onChange={handleChangeSearchData}
                    />
                  </div>
                  <div className="flex-grow-1 ">
                    <InputSearch
                      name="keyword"
                      value={searchData?.keyword || ""}
                      placeholder="Tìm kiếm theo mã phiếu"
                      handleChange={handleChangeSearchData}
                      handleSearch={updatePageData}
                      handleKeyDown={(e: React.KeyboardEvent) =>
                        e.key === KEY.ENTER && updatePageData()
                      }
                      className="spaces h-31 pr-4  radius-3"
                    />
                  </div>
                </div>
                <div className="spaces bg-white mb-0">
                  {warehouseTransferType?.code === CODE_DIEU_CHUYEN.NHAP_DIEU_CHUYEN &&
                    <TableCustom<any>
                      className="spaces h-calc-vh-208"
                      data={dsPhieuDieuChuyen}
                      columns={DanhSachPhieuColumns}
                      columnsActions={columnsActionsNhapDieuChuyen}
                      verticalScroll={true}
                      selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
                      getSelectedRowsData={() => { }}
                    />
                  }
                  {warehouseTransferType?.code === CODE_DIEU_CHUYEN.XUAT_DIEU_CHUYEN &&
                    <TableCustom<any>
                      className="spaces h-calc-vh-208"
                      data={dsPhieuDieuChuyen}
                      columns={DanhSachPhieuColumns}
                      columnsActions={columnsActionsXuatDieuChuyen}
                      verticalScroll={true}
                      selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
                      getSelectedRowsData={() => { }}
                    />
                  }
                  <TablePagination
                    handlePagesChange={handlePagesChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    rowsPerPage={rowsPerPage}
                    rowsForPage={rowsForPage}
                    page={page}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    totalPages={Number(configTable.totalPages)}
                    totalElements={configTable.totalElements}
                    numberOfElements={configTable.numberOfElements}
                    hideTotalElements
                    customComponent={
                      <WfStatusSummary col={2} summaryStatus={summaryStatus} />
                    }
                    className="border border-0"
                    customComponentClassName="spaces min-w-900px"
                  />
                </div>
              {/* </div> */}
            </div>
          </div>
          {openDialogThemMoi && (
            <DialogPhieuDieuChuyen
              show={openDialogThemMoi}
              onHide={handleCloseDialog}
              initialValues={{ dieuChuyen: {
                ...itemSelected,
                searchDatatransferWh: FILTER_WAREHOUSE.includes(warehouseId)
                ? { codeNot: 'DR01' } 
                : null
              }}}
              setInitialValues={setItemSelected}
              warehouseId={warehouseId}
              warehouseTransferType={warehouseTransferType}
            />
          )}
          {openDialogConfirmDelete && (
            <ConfirmDialog
              show={openDialogConfirmDelete}
              onCloseClick={handleCloseDialog}
              onYesClick={handleDelete}
              title="Xóa phiếu điều chuyển"
              message={`Bạn chắc chắn muốn xóa phiếu: ${itemSelected.code} ?`}
              yes="Xóa"
              close="Hủy"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FormDieuChuyen;
