import { Column } from "react-table";
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import { TableCustomCell } from "../../component/table/components/TableCustomCell";
import { formatDateToDDMMYYYY, formatDateAdvanceToString, formatMoney } from "../../utils/FormatUtils";
import { ColumnConfig } from "../../component/table/table-generate/ColumnConfig";
import { generateColumns } from "../../component/table/table-generate/GenerateColumns";


export const columnsNCC: ReadonlyArray<Column<any>> = [
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="STT"
                className="text-center text-light max-w-35"
            />
        ),
        id: "stt",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-center "
                data={String(props?.row?.index + 1)}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Mã NCC"
                className="text-white text-center align-middle min-w-100px"
            />
        ),
        id: "code",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-center"
                data={props.data[props.row.index]?.code}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Tên NCC"
                className=" text-center text-white align-middle bg-pri min-w-200px"
            />
        ),
        id: "name",
        Cell: ({ ...props }) => (
            <TableCustomCell
                data={props.data[props.row.index]?.name}
                className="text-left"
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Địa chỉ"
                className=" text-center text-white align-middle bg-pri min-w-300px"
            />
        ),
        id: "address",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="s-2"
                data={props.data[props.row.index]?.address}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Điện thoại"
                className="text-center text-white align-middle bg-pri min-w-100px"
            />
        ),
        id: "SDT",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="s-2"
                data={props.data[props.row.index]?.SDT}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Ghi chú"
                className=" text-center text-white align-middle bg-pri min-w-200px"
            />
        ),
        id: "notes",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className=" s-2"
                data={props.data[props.row.index]?.notes}
            />
        ),
    },
];

export const columnsNSX: ReadonlyArray<Column<any>> = [
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="STT"
                className="text-center text-light max-w-35"
            />
        ),
        id: "stt",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-center "
                data={String(props?.row?.index + 1)}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Mã nước sản xuất"
                className="text-white text-center align-middle min-w-100px"
            />
        ),
        id: "code",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-center"
                data={props.data[props.row.index]?.code}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Tên nước sản xuất"
                className=" text-center text-white align-middle bg-pri min-w-200px"
            />
        ),
        id: "name",
        Cell: ({ ...props }) => (
            <TableCustomCell
                data={props.data[props.row.index]?.name}
                className="text-left"
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Ghi chú"
                className=" text-center text-white align-middle bg-pri min-w-200px"
            />
        ),
        id: "notes",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className=" s-2"
                data={props.data[props.row.index]?.notes}
            />
        ),
    },
];

export const columnsHSX: ReadonlyArray<Column<any>> = [
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="STT"
                className="text-center text-light max-w-35"
            />
        ),
        id: "stt",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-center "
                data={String(props?.row?.index + 1)}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Mã hãng SX"
                className="text-white text-center align-middle min-w-100px"
            />
        ),
        id: "code",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-center"
                data={props.data[props.row.index]?.code}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Tên hãng SX"
                className=" text-center text-white align-middle bg-pri min-w-200px"
            />
        ),
        id: "name",
        Cell: ({ ...props }) => (
            <TableCustomCell
                data={props.data[props.row.index]?.name}
                className="text-left"
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Địa chỉ"
                className=" text-center text-white align-middle bg-pri min-w-300px"
            />
        ),
        id: "address",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="s-2"
                data={props.data[props.row.index]?.address}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Điện thoại"
                className="text-center text-white align-middle bg-pri min-w-100px"
            />
        ),
        id: "SDT",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="s-2"
                data={props.data[props.row.index]?.SDT}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Ghi chú"
                className=" text-center text-white align-middle bg-pri min-w-200px"
            />
        ),
        id: "notes",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className=" s-2"
                data={props.data[props.row.index]?.notes}
            />
        ),
    },
];

const columnNCC: ColumnConfig[] = [
    {
        id: "stt",
        title: "STT",
        classCell: "text-center w-50px",
        classColumn: "w-50px",
    },
    {
        id: "code",
        title: "Mã NCC",
        classCell: "text-center",
        classColumn: "align-middle min-w-100px",
    },
    {
        id: "name",
        title: "Tên NCC",
        classColumn: " align-middle bg-pri min-w-200px",
    },
    {
        id: "address",
        title: "Địa chỉ",
        classColumn: " align-middle bg-pri min-w-300px",
    },
    {
        id: "SDT",
        title: "Điện thoại",
        classCell: "text-center",
        classColumn: " align-middle bg-pri min-w-100px",
    },
    {
        id: "notes",
        title: "Ghi chú",
        classColumn: " align-middle bg-pri min-w-200px",
    },
];

const columnHSX: ColumnConfig[] = [
    {
        id: "stt",
        title: "STT",
        classCell: "text-center w-50px",
        classColumn: "w-50px",
    },
    {
        id: "code",
        title: "Mã hãng SX",
        classCell: "text-center",
        classColumn: "align-middle min-w-100px",
    },
    {
        id: "name",
        title: "Tên hãng SX",
        classColumn: " align-middle bg-pri min-w-200px",
    },
    {
        id: "address",
        title: "Địa chỉ",
        classColumn: " align-middle bg-pri min-w-300px",
    },
    {
        id: "SDT",
        title: "Điện thoại",
        classCell: "s-2",
        classColumn: " align-middle bg-pri min-w-100px",
    },
    {
        id: "notes",
        title: "Ghi chú",
        classColumn: " align-middle bg-pri min-w-200px",
    },
];

const columnNSX: ColumnConfig[] = [
    {
        id: "stt",
        title: "STT",
        classCell: "text-center w-50px",
        classColumn: "w-50px",
    },
    {
        id: "code",
        title: "Mã nước sản xuất",
        classCell: "text-center",
        classColumn: " align-middle min-w-100px",
    },
    {
        id: "name",
        title: "Tên nước sản xuất",
        classColumn: " align-middle bg-pri min-w-200px",
    },
    {
        id: "notes",
        title: "Ghi chú",
        classColumn: " align-middle bg-pri min-w-200px",
        classCell: " s-2",
    },
];
const columnDD: ColumnConfig[] = [
    {
        id: "stt",
        title: "STT",
        classCell: "text-center w-50px",
        classColumn: "w-50px",
    },
    {
        id: "code",
        title: "Mã đường dùng",
        classCell: "text-center",
        classColumn: " align-middle min-w-100px",
    },
    {
        id: "name",
        title: "Tên đường dùng",
        classColumn: " align-middle bg-pri min-w-200px",
    },
    {
        id: "usageBYTName",
        title: "Tên đường dùng BYT",
        classColumn: " align-middle bg-pri min-w-200px",
    },
];
const columnHC: ColumnConfig[] = [
    {
        id: "stt",
        title: "STT",
        classCell: "text-center w-50px",
        classColumn: "w-50px",
    },
    {
        id: "code",
        title: "Mã hoạt chất",
        classCell: "text-center",
        classColumn: " align-middle min-w-100px",
    },
    {
        id: "codeBYT",
        title: "Mã hoạt chất BYT",
        classColumn: " align-middle bg-pri min-w-100px",
    },
    {
        id: "name",
        title: "Tên hoạt chất",
        classCell: "text-center",
        classColumn: " align-middle min-w-200px",
    },
    {
        id: "ROAName",
        title: "Đường dùng",
        classColumn: " align-middle bg-pri min-w-100px",
    },
];
const columnDVT: ColumnConfig[] = [
    {
        id: "stt",
        title: "STT",
        classCell: "text-center w-50px",
        classColumn: "w-50px",
    },
    {
        id: "code",
        title: "Mã Đơn vị tính",
        classCell: "text-center",
        classColumn: " align-middle min-w-100px",
    },
    {
        id: "name",
        title: "Tên Đơn vị tính",
        classColumn: " align-middle bg-pri min-w-300px",
    },
];

export const columnDM = {
    NCC: generateColumns(columnNCC),
    HSX: generateColumns(columnHSX),
    NSX: generateColumns(columnNSX),
    DVT: generateColumns(columnDVT),
    DD: generateColumns(columnDD),
    HC: generateColumns(columnHC),

};
