export interface VatTu {
    itemId?: string;
    themDanhSachVatTu: VatTuGoiThauInterface;
};





export interface VatTuGoiThauInterface {
    columns2ColumnsText: string
};

export const initialValuesVatTuGoiThau: VatTuGoiThauInterface = {
    columns2ColumnsText: null || "",
};

export const initialValuesVatTu: VatTu = {
    itemId: null || "",
    themDanhSachVatTu: initialValuesVatTuGoiThau,
}
