import { ChangeEvent, FC, useState } from 'react';
import { Form, FormControl } from 'react-bootstrap';

type Props = {
  value?: string
  name?: string
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleKeyDown?: (e: any) => void
  handleSearch?: (searchValue: string | undefined) => void;
  handleBlur?: (e: ChangeEvent<HTMLInputElement>) => void
  placeholder?: string;
  type?: string;
  className?: string;
  size?: "sm" | "lg";
  onKeyPress?: (event: React.KeyboardEvent) => void;
}

const InputSearch: FC<Props> = ({ ...props }) => {
    let {
      value,
      name,
      handleChange,
      handleKeyDown,
      handleSearch,
      type,
      className,
      placeholder,
      size,
      onKeyPress,
    } = props;

    const [waitValue, setWaitValue] = useState<string>();
    const handleChangeValue = (e:  ChangeEvent<HTMLInputElement>) => {
      setWaitValue(e.target.value);
      handleChange(e);
    };

    return (
      <Form.Group className="position-relative">
        <FormControl
          className={`form-control customs-input ${className ? className : ""}`}
          value={value}
          name={name}
          onChange={handleChangeValue}
          onKeyDown={handleKeyDown}
          placeholder={placeholder ? placeholder : ""}
          type={type ? type : "text"}
          size={size}
          onKeyUp={onKeyPress}
        />

        <div className="searchTextField" onClick={() => handleSearch && handleSearch(waitValue)}>
          <i className="bi bi-search"></i>
        </div>
      </Form.Group>
    );
};

export default InputSearch