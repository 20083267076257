import { Formik } from "formik";
import CustomTabMenu from "../component/CustomTabMenu";
import { danhSachTabKhoVatTu } from "./Constant";

export const KhoVatTu: React.FC = () => {

  return (
    <Formik<any> initialValues={{}} onSubmit={() => { }}>
      <CustomTabMenu
        danhsachTabs={danhSachTabKhoVatTu}
        className="spaces pl-0"
      />
    </Formik>
  );
};

export default KhoVatTu;
