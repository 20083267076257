import clsx from 'clsx';
import moment from 'moment';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker, { DatePickerProps } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';

const MODE = ['date', 'time', 'date-time'] as const;

type Mode = typeof MODE[number];

const DEFAULT_DATE_FORMAT: Record<Mode, string> = {
	date: 'dd/MM/yyyy',
	time: 'HH:mm',
	'date-time': 'HH:mm dd/MM/yyyy',
};

const DEFAULT_OUTPUT_FORMAT: Record<Mode, string> = {
	date: 'YYYY-MM-DDTHH:mm:ss',
	time: 'HH:mm',
	'date-time': 'YYYY-MM-DDTHH:mm:ss',
};

type IProps = {
	label?: string;
	name: string;
	required?: boolean;
	placeholderText?: string;
	dateFormat?: string;
	dateFormatOutput?: string;
	labelClassName?: string;
	inputClassName?: string;
	className?: string;
	setDateValue?: (date: string | null) => void;
	handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	errors?: any;
	touched?: any;
	value: string | null;
	minDate?: Date;
	maxDate?: Date;
	mode?: Mode;
	disabled?: boolean;
	[x: string]: any;
};

const DatePickerCustom = ({
	placeholderText = '__/__/____',
	dateFormat = 'dd/MM/yyyy',
	dateFormatOutput,
	label,
	required,
	labelClassName,
	inputClassName,
	className,
	name,
	value,
	setDateValue,
	touched,
	errors,
	handleBlur,
	minDate,
	maxDate,
	mode = 'date',
	disabled,
	...props
}: IProps) => {
	const inputFormat = DEFAULT_DATE_FORMAT[mode];
	const outputFormat = dateFormatOutput || DEFAULT_OUTPUT_FORMAT[mode];
	const date = moment(value, [outputFormat, inputFormat]);
	const hasError = touched && !!errors;

	const [invalid, setInvalid] = useState(false);

	const handleChange = (date: Date | null, event: React.SyntheticEvent<any> | undefined) => {
		if (event?.currentTarget?.value) return;
		setDateValue?.(moment(date).isValid() ? moment(date).format(outputFormat) : null);
		setInvalid(false);
	};

	const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		const inputValue = event.target.value;

		if (inputValue === '') {
			setDateValue?.(null);
			setInvalid(false);
			return;
		}

		const tempFormat = mode === 'date' ? 'DDMMYYYY' : ['HH:mm DDMMYYYY', 'HHmm DDMMYYYY'];
		const valueFomat = moment(inputValue, tempFormat);
		const isValidDate = valueFomat.isValid();

		if (!isValidDate) {
			setDateValue?.(null);
			setInvalid(true);
			return;
		}

		setDateValue?.(valueFomat.format(outputFormat));
	};
	return (
		<Form.Group className={clsx('d-flex align-items-center w-100', className)}>
			{label && (
				<Form.Label
					className={clsx(
						'spaces text-lable-input max-content-width mb-0',
						labelClassName
					)}
				>
					{required ? (
						<span>
							{label}
							<span className="text-danger"> (*)</span>
						</span>
					) : (
						label
					)}
				</Form.Label>
			)}
			<div className="position-relative w-100">
				<DatePicker
					className={clsx('form-control customs-input w-100', {
					'is-invalid ': !!hasError || invalid,
					})}
					showIcon={true}
					icon="bi bi-calendar4-week"
					timeIntervals={1}
					toggleCalendarOnIconClick
					minDate={minDate}
					maxDate={maxDate}
					wrapperClassName={clsx('oct-date-picker-wrapper w-100', {
						'is-invalid ac-is-invalid': !!hasError || invalid,
					})}
					popperClassName="oct-date-picker-popper"
					selected={date.isValid() ? date.toDate() : null}
					dateFormat={inputFormat}
					placeholderText={placeholderText}
					name={name}
					onChange={handleChange}
					onBlur={onBlur}
					timeFormat="HH:mm"
					disabled={disabled}
					showPopperArrow={false}
					showTimeSelectOnly={mode === 'time'}
					showTimeSelect={mode === 'date-time' || mode === 'time'}
					portalId="root-portal"
					{...props}
				/>
				<Form.Control.Feedback type="invalid" tooltip className="field-tooltip-error">
					{errors}
				</Form.Control.Feedback>
			</div>
		</Form.Group>
	);
};

export default DatePickerCustom;
