import React, { useContext, useEffect, useState } from "react";
import GenerateFormHTML from "../../../../component/generate-form/GenerateFormHTMLNoValid";
import { generateForm } from "../../../../utils/AppFunction";
import { AppContext } from "../../../../appContext/AppContext";
import { useFormikContext } from "formik";
import { VatTu } from "../models/ThemMoiVatTuModel";
import { PhanHeThongKeContextProps, PhanHeThongKeContext } from "../../../PhanHeThongKe";

type Props = {
  modelID?: any,
  listJsonGenerate?: any,
};

const FormThongTinChung = ({
  modelID,
  listJsonGenerate
}: Props) => {


  const {
    handleSubmit,
    setValues,
    setTouched,
    setFieldValue,
    values,
    errors,
    touched,
    isValid,
    handleChange,
  } = useFormikContext<VatTu>();
  const { setIsLoading } = useContext(AppContext);
  const { nhomVatTu, dataVatTuEdit } = useContext<PhanHeThongKeContextProps>(PhanHeThongKeContext);
  const [isView, setIsView] = useState<any>(false);
  const [data, setData] = useState<any>({});
  const [model, setModel] = useState<any>(1);

  return (
    <div className="bg-white spaces mb-6 pb-15 fl-1 ml-10 mr-10">
      <GenerateFormHTML
        isView={false}
        listFieldAuto={listJsonGenerate}
        // itemEdit={dataVatTuEdit?.thongTinChung}
        isSave={false}
        isUpdate={true}
        modelID={nhomVatTu?.description || "thongTinChung"}
        handleSubmit={handleSubmit}
        setValues={setValues}
        setTouched={setTouched}
        setFieldValue={setFieldValue}
        values={values}
        errors={errors}
        touched={touched}
        isValid={isValid}
        handleChange={handleChange}
      />
    </div>
  );
};

export default FormThongTinChung;
