import { Dispatch, SetStateAction } from "react";
import "../PhanHeThuNgan.scss";
import { Button } from "react-bootstrap";

type Props = {
  selectedRow: any
  setShowDialogThemMoi: Dispatch<SetStateAction<boolean>>
};

const ThongTinBenhNhan = (props: Props) => {
  const { selectedRow = {}, setShowDialogThemMoi } = props;

  return (
    <div className="bg-white position-relative spaces gap-6 spaces w-100 mb-6 fl-1">
      <div className="spaces w-100">
        <div className="border-bottom spaces px-10 pt-5 flex justify-content-between">
          <div>
            <Button
              className="btn btn-fill spaces mt-4"
              onClick={() => setShowDialogThemMoi(true)}
            >
              Thêm phiếu
            </Button>
          </div>
          <div>
            <h4 className="text-title fw-bold text-end fs-4 spaces mb-0 line-height-24">
                {selectedRow.code
                  ? `${selectedRow.code} | ${selectedRow.patientName} | ${selectedRow.age || '...'} tuổi | ${selectedRow.patientGenderName}`
                  : 'Thông tin bệnh nhân'
                }
            </h4>
            <p className="text-end spaces mb-4">
              {selectedRow.patientAddress ? selectedRow.patientAddress : 'Địa chỉ: ...'}
            </p>
          </div>
        </div>
        <div className="spaces w-100 justify-content-center p-10">
          <div className="flex flex-wrap">
            <div className="flex spaces w-50">
              <span className="text-ellipsis-title fw-bold">
                Tạo phiếu:
              </span>
              <span className="text-ellipsis-content">
                {selectedRow.createSlip || '...'}
              </span>
            </div>
            <div className="flex spaces w-50">
              <span className="text-ellipsis-title fw-bold">
                Kho:
              </span>
              <span className="text-ellipsis-content">
                {selectedRow.mateWhName || '...'}
              </span>
            </div>
            <div className="flex spaces w-50">
              <span className="text-ellipsis-title fw-bold">
                Duyệt phiếu:
              </span>
              <span className="text-ellipsis-content">
                {selectedRow.approveSlip || '...'}
              </span>
            </div>
            <div className="flex spaces w-50">
              <span className="text-ellipsis-title fw-bold">
                Chẩn đoán:
              </span>
              <span className="text-ellipsis-content">
                {selectedRow.diagnosis || '...'}
              </span>
            </div>
            <div className="flex spaces w-50">
              <span className="text-ellipsis-title fw-bold">
                Xuất phiếu:
              </span>
              <span className="text-ellipsis-content">
                {selectedRow.exportSlip || '...'}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThongTinBenhNhan;
